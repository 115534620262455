<template>
  <resource-list
      :title="$route.meta.title"
      :icon="$route.meta.icon"
      resource="user"
      :fields="[
          { name: 'user_id', label: this.$vuetify.lang.t('$vuetify.views.routes.common.fields.id') },
          { name: 'contact_id', label: this.$vuetify.lang.t('$vuetify.views.routes.common.fields.contact_id'), visible: { list: false, edit: true } },
          { name: 'contact.title', label: this.$vuetify.lang.t('$vuetify.views.routes.common.fields.contact_id') },
          { name: 'username', label: this.$vuetify.lang.t('$vuetify.views.routes.users.fields.user_name') },
          { name: 'role', label: this.$vuetify.lang.t('$vuetify.views.routes.users.fields.role'), type: 'list', list: userRoles },
          { name: 'active', label: this.$vuetify.lang.t('$vuetify.views.routes.common.fields.active') },
        ]"
  />
</template>

<script>
import ResourceList from '@/components/ResourceList'
import rbac from '@/repositories/cb-server/api/rbac'

export default {
  name: 'VUsers',

  components: { ResourceList },

  data: () => ({
    roles: []
  }),

  async mounted () {
    this.roles = await rbac.roles()
  },

  computed: {
    userRoles () {
      return this.roles?.map(
          item => ({
            name: item.name,
            value: item.name,
            item
          })
      )
    }
  },
}
</script>

<style scoped>

</style>
