<template>
  <div>
    <div class="ma-0 pa-0" v-for="(group, gi) in groups" :key="`gridGroup_${gi}`">
      <v-card class="ma-2 pa-0" elevation="3">
        <v-card-title><slot name="group" v-bind:group="group"/></v-card-title>
        <v-card-text>
          <v-row v-for="(row, ri) in group.rows" :key="`gridGroupRow_${gi}${ri}`">
            <v-col v-for="(col, ci) in row" :key="`propCol_${gi}${ri}${ci}`" cols="12" md="6" sm="12">
              <slot name="cell" v-bind:item="col"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import IsResource from '@/mixins/IsResource'
import flow from 'lodash/fp/flow'
import groupBy from 'lodash/fp/groupBy'
import {getProperty} from '@/utils/common'

export default {
  name: 'VResourceGrid',
  props: {
    title: {
      type: String,
    },
    icon: {
      type: String,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    colCount: {
      type: Number,
      default: 2,
    },
    cellTitleField: {
      type: String,
      default: 'title'
    },
    cellValueField: {
      type: String,
      default: null
    },
    groupBy: {
      type: String,
      default: null,
    },
    groupTitle: {
      type: Array || String,
      default: () => [],
    }
  },
  mixins: [IsResource],
  computed: {
    groups () {
      const groups = []

      if (!this.resourceData) {
        return groups
      }

      const data = flow(
          groupBy(item => getProperty(item, this.groupBy)),
      )(this.resourceData)

      // build rows for groups
      for (const [name, group] of Object.entries(data)) {
        const items = []
        const rows = []
        let cols = []
        let first = null

        for (const item of group) {
          first = first || item

          cols.push(item)

          if (cols.length >= this.colCount) {
            rows.push(cols)
            cols = []
          }

          items.push(item)
        }

        if (cols.length) {
          rows.push(cols)
        }

        groups.push({ name: name, first, items, rows })
      }

      return groups
    },
  },
}
</script>

<style scoped>

</style>
