export default {
    get (name) {
        return JSON.parse(window.localStorage.getItem(name))
    },

    set (name, value) {
        if (value) {
            window.localStorage.setItem(name, JSON.stringify(value))
        } else {
            window.localStorage.removeItem(name)
        }
    },
}
