import {generateHash} from '@/utils/common'

const state = {
    /**
     * Message list to be shown at to top of the page.
     * @type {{ id: String, text: String, level: 'info'|'warning'|'error', timeout: Number }[]}
     */
    messages: []
};

function createMessage(commit, text, level, timeout = 5000) {
    const message = {
        id: generateHash(8),
        text, level, timeout,
    }

    setTimeout(() => commit('remove', message), message.timeout)

    return message
}

const actions = {
    info({ commit }, message) {
        commit('add', createMessage(commit, message, 'info'));
    },
    warning({ commit }, message) {
        commit('add', createMessage(commit, message, 'warning'));
    },
    error({ commit }, message) {
        commit('add', createMessage(commit, message, 'error'));
    },
    remove({ commit }, message) {
        commit('remove', message);
    },
    clear({ commit }) {
        commit('clear');
    }
};

const mutations = {
    add(state, message) {
        if (message.text) {
            state.messages = [...state.messages, message]
        }
    },
    remove(state, message) {
        state.messages = state.messages.filter(item => item.id !== message.id)
    },
    clear(state) {
        state.messages = []
    }
};

export const message = {
    namespaced: true,
    state,
    actions,
    mutations
};
