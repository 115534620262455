var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('resource-list',{attrs:{"title":_vm.$route.meta.title,"icon":_vm.$route.meta.icon,"resource":"task","filter":{ customer_id: _vm.customer },"fields":[
        { name: 'task_id', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.id') },
        { name: 'customer_id', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.customer_id'), visible: { list: false, edit: true } },
        { name: 'report_id', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.report_id'), visible: { list: false, edit: true } },
        { name: 'schedule_id', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.schedule_id'), visible: { list: false, edit: true } },
        { name: 'output_id', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.output_id'), visible: { list: false, edit: true } },
        { name: 'title', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.title') },
        { name: 'report.title', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.report_id'), visible: { list: true, edit: false } },
        { name: 'schedule.title', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.schedule_id'), visible: { list: true, edit: false } },
        { name: 'output.title', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.output_id'), visible: { list: true, edit: false } } ],"relations":[
      { name: 'contact_id_contacts', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.relations.contacts_title') }
    ]}})}
var staticRenderFns = []

export { render, staticRenderFns }