<template>
  <resource-list
      :title="$route.meta.title"
      :icon="$route.meta.icon"
      resource="contact"
      :filter="filter"
      :fields="[
          { name: 'contact_id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.id') },
          { name: 'firstname', label: $vuetify.lang.t('$vuetify.views.routes.contacts.fields.first_name') },
          { name: 'lastname', label: $vuetify.lang.t('$vuetify.views.routes.contacts.fields.last_name') },
          { name: 'email', label: $vuetify.lang.t('$vuetify.views.routes.contacts.fields.email') },
          { name: 'phone', label: $vuetify.lang.t('$vuetify.views.routes.contacts.fields.phone') },
        ]"
      @field:changed="fieldChanged"
      ref="list"
  >
    <template v-slot:buttons>
      <v-btn
          v-if="canReadAny"
          color="warning"
          class="mb-2 mr-2"
          @click="toggleFilter"
          dark
      >
        {{ $vuetify.lang.t('$vuetify.common.clear_filter') }}
      </v-btn>
    </template>
  </resource-list>
</template>

<script>
import ResourceList from '@/components/ResourceList'
import {mapState} from 'vuex'
import HasRBAC from '@/mixins/HasRBAC'

export default {
  name: 'VContacts',
  components: { ResourceList },
  mixins: [HasRBAC],
  data () {
    return {
      filterActive: true,
    }
  },
  computed: {
    ...mapState('customer', ['customer']),

    filter () {
      return this.filterActive ? { customer_id: this.customer } : null
    },

    canReadAny () {
      return this.canReadAnyResource('contact')
    },
  },
  methods: {
    fieldChanged (field, item) {
      if (['firstname', 'lastname', 'email'].includes(field.name)) {
        item.title = `"${item.firstname} ${item.lastname}" <${item.email}>`
      }
    },

    toggleFilter () {
      this.filterActive = !this.filterActive
      this.$refs.list.reloadItems()
    }
  }
}
</script>

<style scoped>

</style>
