<template>
  <v-container>
    <v-row v-for="(row, i) in rows" :key="`propRow${i}`">
      <v-col v-for="field in row" :key="`propCol_${field.name}`" cols="12" sm="6">
        <v-resource-property
            v-model="item[field.name]"
            :field="field"
            :filter="filter"
            :read-only="readOnly"
            @change="fieldChanged"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div v-for="r in related" :key="r.name">
          <v-toolbar-title>{{ r.label }}</v-toolbar-title>
          <v-data-table
              v-model="item[r.name]"
              :headers="[{
                text: $vuetify.lang.t('$vuetify.components.resource_edit.value_title'),
                align: 'start',
                value: 'title',
                width: '100%'
              }]"
              :items="r.data"
              :single-select="false"
              :item-key="r.targetKey"
              :show-select="!readOnly && !r.readOnly"
              hide-default-header
              hide-default-footer
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VResourceProperty from './ResourceProperty'
import resource from '@/repositories/cb-server/api/resource'

/**
 * @typedef {
 *   name: string,
 *   type: 'BelongsToMany' | 'BelongsTo' | 'HasMany',
 *   label: string,
 *   multi: boolean,
 *   name: string,
 *   resource: string,
 *   sourceKey: string,
 *   targetKey: string,
 *   data: array
 * } RelationDef
 */


export default {
  name: 'VResourceEdit',
  components: { VResourceProperty },
  props: {
    fields: {
      type: Array,
    },

    relations: {
      type: Array,
      default: () => []
    },

    item: {
      type: Object,
    },

    colCount: {
      type: Number,
      default: 2,
    },

    filter: {
      type: Object,
    },

    readOnly: {
      type: Boolean,
      default: false,
    },

    defaults: {
      type: Object,
      default: () => ({})
    },
  },

  data: () => ({
    related: [],
  }),

  mounted () {
    this.loadRelations()
    this.loadDefaults()
  },

  computed: {
    rows () {
      const result = []
      let group = []

      for (const field of this.fields) {
        group.push(field)

        if (group.length >= this.colCount) {
          result.push(group)
          group = []
        }
      }

      if (group.length) {
        result.push(group)
      }

      return result
    },
  },

  methods: {
    fieldChanged (field) {
      this.$emit('field:changed', field, this.item, this.item[field.name])
    },

    async loadDefaults () {
      for (const [name, value] of Object.entries(this.defaults)) {
        if (typeof this.item[name] === 'undefined') {
          this.item[name] = typeof value === 'function' ? value() : value
        }
      }
    },

    async loadRelations () {
      this.related = []

      const relations = this.relations.filter(related => ['BelongsToMany', 'HasMany'].includes(related.type))

      for (const relation of relations) {
        const filter = { ...this.filter }

        if (relation.type === 'HasMany') {
          filter[relation.sourceKey] = this.item[relation.sourceKey]
        }

        const api = resource(relation.resource)

        relation.data = await api.list(filter)
        relation.data.forEach(
          item => item.title = relation.title
            ? item[relation.title]
            : item.title
        )

        this.related.push(relation)
      }
    }
  }
}
</script>

<style scoped>

</style>
