import Vue from 'vue'
import VueRouter from 'vue-router'

import Customers from '../views/AdminView/routes/Customers'
import Buildings from '../views/AdminView/routes/Buildings'
import Contacts from '../views/AdminView/routes/Contacts'
import Users from '../views/AdminView/routes/Users'
import Tools from '../views/AdminView/routes/Tools'
import Meters from '@/views/AdminView/routes/Meters/index'
import Reports from '@/views/AdminView/routes/Reports'
import Schedules from '@/views/AdminView/routes/Schedules'
import Tasks from '@/views/AdminView/routes/Tasks'
import Outputs from '@/views/AdminView/routes/Outputs'
import Sensors from '@/views/AdminView/routes/Sensors'
import Tenants from '@/views/AdminView/routes/Tenants'
import Tokens from '@/views/AdminView/routes/Tokens'
import Generator from '@/views/AdminView/routes/Generators'
import Jobs from '@/views/AdminView/routes/Jobs'
import Logs from '@/views/AdminView/routes/Logs'
import { $t } from '@/plugins/vuetify'
import rbac from '@/services/rbac'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/customer',
    component: Customers,
    meta: {
      icon: 'fa-user-tie',
      title: $t('$vuetify.menu.items.customers'),
      group: $t('$vuetify.menu.groups.people'),
      rbac: {
        resource: 'customer',
        group: 'people',
      }
    }
  },
  {
    path: '/building',
    component: Buildings,
    meta: {
      title: $t('$vuetify.menu.items.buildings'),
      icon: 'fa-building',
      group: $t('$vuetify.menu.groups.network'),
      rbac: {
        resource: 'building',
        group: 'network',
      },
    }
  },
  {
    path: '/sensors',
    component: Sensors,
    meta: {
      title: $t('$vuetify.menu.items.sensors'),
      icon: 'fa-microchip',
      group: $t('$vuetify.menu.groups.network'),
      rbac: {
        resource: 'sensor',
        group: 'network',
      },
    }
  },
  {
    path: '/meters',
    component: Meters,
    meta: {
      title: $t('$vuetify.menu.items.meters'),
      icon: 'fa-tachometer-alt',
      group: $t('$vuetify.menu.groups.network'),
      rbac: {
        resource: 'meter',
        group: 'network',
      },
    }
  },
  {
    path: '/reports',
    component: Reports,
    meta: {
      title: $t('$vuetify.menu.items.reports'),
      icon: 'fa-table',
      group: $t('$vuetify.menu.groups.reporting'),
      rbac: {
        resource: 'report',
        group: 'reporting',
      },
    }
  },
  {
    path: '/schedules',
    component: Schedules,
    meta: {
      title: $t('$vuetify.menu.items.schedules'),
      icon: 'fa-calendar-alt',
      group: $t('$vuetify.menu.groups.reporting'),
      rbac: {
        resource: 'schedule',
        group: 'reporting',
      },
    }
  },
  {
    path: '/tasks',
    component: Tasks,
    meta: {
      title: $t('$vuetify.menu.items.tasks'),
      icon: 'fa-mail-bulk',
      group: $t('$vuetify.menu.groups.reporting'),
      rbac: {
        resource: 'task',
        group: 'reporting',
      },
    }
  },
  {
    path: '/outputs',
    component: Outputs,
    meta: {
      title: $t('$vuetify.menu.items.outputs'),
      icon: 'fa-plug',
      group: $t('$vuetify.menu.groups.reporting'),
      rbac: {
        resource: 'output',
        group: 'reporting',
      },
    }
  },
  {
    path: '/generators',
    component: Generator,
    meta: {
      title: $t('$vuetify.menu.items.generators'),
      icon: 'fa-file-code',
      group: $t('$vuetify.menu.groups.reporting'),
      rbac: {
        resource: 'generator',
        group: 'reporting',
      },
    }
  },
  {
    path: '/users',
    component: Users,
    meta: {
      title: $t('$vuetify.menu.items.users'),
      icon: 'fa-users',
      group: $t('$vuetify.menu.groups.people'),
      rbac: {
        resource: 'user',
        group: 'people',
      },
    }
  },
  {
    path: '/contacts',
    component: Contacts,
    meta: {
      title: $t('$vuetify.menu.items.contacts'),
      icon: 'fa-address-book',
      group: $t('$vuetify.menu.groups.people'),
      rbac: {
        resource: 'contact',
        group: 'people',
      },
    }
  },
  {
    path: '/tenants',
    component: Tenants,
    meta: {
      title: $t('$vuetify.menu.items.tenants'),
      icon: 'fa-house-user',
      group: $t('$vuetify.menu.groups.people'),
      rbac: {
        resource: 'tenant',
        group: 'people',
      },
    }
  },
  {
    path: '/tokens',
    component: Tokens,
    meta: {
      title: $t('$vuetify.menu.items.tokens'),
      icon: 'fa-key',
      group: $t('$vuetify.menu.groups.people'),
      rbac: {
        resource: 'token',
        group: 'people',
      },
    }
  },
  {
    path: '/jobs',
    component: Jobs,
    meta: {
      title: $t('$vuetify.menu.items.jobs'),
      icon: 'fa-hourglass-half',
      group: $t('$vuetify.menu.groups.system'),
      rbac: {
        resource: 'scheduler',
        group: 'system',
      },
    }
  },
  {
    path: '/logs',
    component: Logs,
    meta: {
      title: $t('$vuetify.menu.items.logs'),
      icon: 'fa-align-left',
      group: $t('$vuetify.menu.groups.system'),
      rbac: {
        resource: 'log',
        group: 'system',
      },
    }
  },
  {
    path: '/tools',
    component: Tools,
    meta: {
      title: $t('$vuetify.menu.items.tools'),
      icon: 'fa-tools',
      group: $t('$vuetify.menu.groups.system'),
      rbac: {
        resource: 'tools',
        group: 'system',
      },
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.rbac && rbac.check(['readOwn', 'readAny'], to.meta.rbac.resource)) {
    next()
  } else if (to.path !== '/') {
    next({
      path: '/',
      replace: true
    })
  }
})

export default router
