import storage from '../utils/storage'
import auth from '@/repositories/cb-server/api/auth'
import rbacService from '@/services/rbac'
import rbac from '@/repositories/cb-server/api/rbac'

const state = {
    status: {},
    user: storage.get('user') || {},
    authorization: storage.get('authorization'),
    grants: storage.get('grants'),
}

try {
    rbacService.init(state.grants, state.user.role)
} catch (e) {
    state.user = {}
    state.authorization = null
    state.grants = null
}

state.status = state.authorization
    ? { loggedIn: true }
    : {}

const actions = {
    async login({ dispatch, commit }, { username, password }) {
        try {
            commit('loginRequest')

            const response = await auth.login(username, password)

            await commit('loginSuccess', response)
            dispatch('message/info', 'Login success! :)', { root: true })
            dispatch('customer/refresh', null, { root: true })
        } catch (e) {
            commit('loginFailure', e.message)
            dispatch('message/error', e.message, { root: true })
        }
    },
    async logout({ dispatch, commit }) {
        try {
            const response = await auth.logout()

            await commit('logout', response)
            dispatch('message/info', 'Logout success... Bye!', { root: true })
        } catch (e) {
            await commit('logout', e.message)
            dispatch('message/error', e.message, { root: true })
        }
    },
}

const mutations = {
    loginRequest(state) {
        state.status = { loggingIn: true }
    },

    async loginSuccess(state, response) {
        state.status = { loggedIn: true }
        state.authorization = response
        state.authorization.issued = new Date()
        state.user = await auth.me()
        state.grants = await rbac.grants()

        storage.set('authorization', state.authorization)
        storage.set('user', state.user)
        storage.set('grants', state.grants)

        rbacService.init(state.grants, state.user.role)
    },

    loginFailure(state) {
        this.logout(state)
    },

    logout(state) {
        storage.set('authorization', null)
        storage.set('grants', null)

        state.status = {}
        state.authorization = null
        state.user = null
        state.grants = null

        rbacService.deinit()
    },
}

export const account = {
    namespaced: true,
    state,
    actions,
    mutations
}
