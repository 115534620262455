<template>
  <div>
    <v-text-field
        v-if="field.type === 'string'"
        :value="value"
        :label="field.label"
        :required="field.required"
        :counter="field.limit"
        :readonly="readOnly || field.readonly"
        :disabled="readOnly || field.disabled"
        @change="inputChanged"
    />
    <v-textarea
        v-if="field.type === 'memo'"
        :value="value"
        :label="field.label"
        :readonly="readOnly || field.readonly"
        :disabled="readOnly || field.disabled"
        @change="inputChanged"
    />
    <v-text-field
        v-if="field.type === 'number'"
        :value="value"
        :label="field.label"
        :required="field.required"
        :readonly="readOnly || field.readonly"
        :disabled="readOnly || field.disabled"
        type="number"
        @change="inputChanged"
    />
    <v-text-field
        v-if="field.type === 'float'"
        :value="value"
        :label="field.label"
        :required="field.required"
        :readonly="readOnly || field.readonly"
        :disabled="readOnly || field.disabled"
        type="number"
        step="0.001"
        @change="inputChanged"
    />
    <v-datetime-picker
        v-if="field.type === 'datetime'"
        :value="value"
        :label="field.label"
        :required="field.required"
        :readonly="readOnly || field.readonly"
        :disabled="readOnly || field.disabled"
        @change="inputChanged"
    />
    <v-menu
        :value="datepickerVisible"
        v-if="field.type === 'date'"
        :close-on-content-click="true"
        max-width="290"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            :value="value"
            clearable
            v-bind="attrs"
            v-on="on"
            :label="field.label"
            :required="field.required"
            :readonly="readOnly || field.readonly"
            :disabled="readOnly || field.disabled"
            @click:clear="value = null"
            @change="inputChanged"
        ></v-text-field>
      </template>
      <v-date-picker
          :value="value"
          :readonly="readOnly || field.readonly"
          :disabled="readOnly || field.disabled"
          @change="inputChanged"
      ></v-date-picker>
    </v-menu>
    <v-checkbox
        v-if="field.type === 'boolean'"
        :input-value="value"
        :label="field.label"
        :required="field.required"
        :readonly="readOnly || field.readonly"
        :disabled="readOnly || field.disabled"
        @change="inputChanged"
    ></v-checkbox>
    <v-select
        v-if="['reference', 'list'].includes(field.type)"
        :value="value"
        :items="options"
        :item-text="optionText"
        :item-value="optionValue"
        :label="field.label"
        :required="field.required"
        :readonly="readOnly || field.readonly"
        :disabled="readOnly || field.disabled"
        @change="inputChanged"
        :clearable="!field.required"
    ></v-select>
    <div v-if="field.type === 'json'">
      <div class="v-label v-label--active theme--light pb-1"><small>{{ field.label }}</small></div>
      <v-jsoneditor
          :value="value"
          :options="{
            mode: 'code'
          }"
          :required="field.required"
          :readonly="readOnly || field.readonly"
          :disabled="readOnly || field.disabled"
          @input="inputChanged"
      />
    </div>
    <v-coords-picker
        v-if="field.type === 'point'"
        :value="value"
        :label="field.label"
        :required="field.required"
        :readonly="readOnly || field.readonly"
        :disabled="readOnly || field.disabled"
        @input="inputChanged"
    ></v-coords-picker>
  </div>
</template>

<script>
import resource from '@/repositories/cb-server/api/resource'
import VCoordsPicker from '@/components/CoordsPicker'

export default {
  name: 'VResourceProperty',
  components: {VCoordsPicker},
  props: {
    field: {
      type: Object,
    },

    filter: {
      type: Object,
    },

    value: {},

    readOnly: {
      type: Boolean,
      default: false,
    }
  },

  data: () => ({
    options: [],
    optionText: (v, i) => `Item ${i}`,
    optionValue: '',
    datepickerVisible: false,
  }),

  async created () {
    if (this.field.list) {
      this.options = this.field.list
      this.optionText = option => option.name
      this.optionValue = 'value'
      return
    }

    if (this.field.reference) {
      const api = resource(this.field.reference.model)

      this.options = await api.list(this.filter)

      this.optionText = option => option.title || Object.values(option).join('; ')
      this.optionValue = this.field.reference.key
    }
  },

  methods: {
    inputChanged (event) {
      this.$emit('input', event)
      this.$emit('change', this.field, event)
    },
  }
}
</script>

<style scoped>

</style>
