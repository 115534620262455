<template>
  <div>
  <v-snackbar
      v-for="(message,i) in messages"
      :key="message.id"
      :timeout="message.timeout"
      :style="{'margin-bottom': `${i * 60}px`}"
      :value="true"
      transition="fade-transition"
      absolute
      right
      bottom
      app
  >
    {{ message.text }}

    <template v-slot:action="{ attrs }">
      <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="remove(message)"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'VMessages',
  computed: {
    ...mapState('message', ['messages']),
  },
  methods: {
    ...mapActions('message', ['remove'])
  }
}
</script>

<style scoped>

</style>
