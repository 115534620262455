import api from './index'

export default {
  meters (token) {
    return api.get(`/tenants/meters`, { params: { token } })
  },

  live (token) {
    return api.get(`/tenants/live`, { params: { token } })
  },
}
