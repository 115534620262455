import { Bar, mixins } from 'vue-chartjs'

export const defaultBarSeriesOptions = {
    backgroundColor: 'rgba(17,90,185, 0.5)',
    borderColor: 'rgba(17,90,185, 1)',
    borderJoinStyle: 'round',
    hoverBackgroundColor: 'rgba(17,90,185, 0.2)',
    hoverBorderColor: 'rgba(17,90,185, 0.5)',
    borderWidth: 1,
    pointRadius: 0,
}

export const defaultChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
        xAxes: [{
            display: true,
            grid: true,
            ticks: {
            }
        }],
        yAxes: [{
            display: true,
            grid: true,
            ticks: {
            }
        }],
    },
}


export default {
    name: 'VCBBarChart',
    extends: Bar,
    mixins: [mixins.reactiveProp],
    props: {
        options: {
            type: Object,
            default: () => defaultChartOptions
        }
    },
    mounted () {
        this.options.onClick = this.handle
        this.renderChart(this.chartData, this.options)
    },
    methods: {
        handle (point, event) {
            const item = event[0]

            if (item) {
                const index = item._index
                const dataset = this.chartData.datasets[item._datasetIndex]

                this.$emit('chart:click', dataset, index)
            }
        }
    }
}
