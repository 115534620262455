var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-resource-list',{ref:"list",attrs:{"resource":"meter","title":_vm.$vuetify.lang.t('$vuetify.views.routes.meters.live_view.title'),"icon":"fa-heart-pulse","filter":{ customer_id: _vm.customer },"fields":[
      { name: 'meter_id', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.id') },
      { name: 'identifier', label: _vm.$vuetify.lang.t('$vuetify.views.routes.meters.fields.identifier') },
      { name: 'locality', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.locality') },
      { name: 'name', label: _vm.$vuetify.lang.t('$vuetify.views.routes.meters.fields.name'), visible: { list: false, edit: true } },
      { name: 'title', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.title') },
      { name: 'description', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.description') },
      { name: 'total', label: _vm.$vuetify.lang.t('$vuetify.views.routes.meters.live_view.total') },
      { name: 'updated_at', label: _vm.$vuetify.lang.t('$vuetify.views.routes.meters.live_view.updated_at') } ],"group-by":[
        'sensor.title'
    ],"group-title":[
        _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.sensor_id')
    ],"actions-enabled":false},on:{"items":_vm.itemsLoaded},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"color":"warning","dark":""},on:{"click":function($event){return _vm.refreshValues()}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.common.refresh'))+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }