<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="jobs"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-icon v-if="$route.meta.icon" class="mb-1 mr-2">{{ $route.meta.icon }}</v-icon>
          <v-toolbar-title>{{ $route.meta.title.toUpperCase() }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              color="warning"
              class="mb-2 mr-2"
              to="/schedules"
              dark
          >
            {{ $vuetify.lang.t('$vuetify.views.routes.jobs.buttons.schedules') }}
          </v-btn>
          <v-btn
              color="success"
              class="mb-2 mr-2"
              to="/logs"
              dark
          >
            {{ $vuetify.lang.t('$vuetify.views.routes.jobs.buttons.log') }}
          </v-btn>
          <v-btn
              color="error"
              class="mb-2 mr-2"
              @click="restartJobs"
              dark
          >
            {{ $vuetify.lang.t('$vuetify.views.routes.jobs.buttons.restart') }}
          </v-btn>
          <v-btn
              color="primary"
              class="mb-2"
              @click="refreshJobs"
              dark
          >
            {{ $vuetify.lang.t('$vuetify.views.routes.jobs.buttons.refresh') }}
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="runJob(item)"
        >
          mdi-play
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import jobs from '@/repositories/cb-server/api/jobs'

export default {
  name: 'VJobs',

  data: () => ({
    jobs: []
  }),

  async created () {
    await this.refresh()
  },

  computed: {
    headers () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.views.routes.common.fields.id'),
          align: 'start',
          sortable: true,
          value: 'id'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.views.routes.common.fields.name'),
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.views.routes.jobs.fields.recipe'),
          align: 'start',
          sortable: true,
          value: 'cron'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.views.routes.jobs.fields.last_run'),
          align: 'start',
          sortable: true,
          value: 'last'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.views.routes.common.fields.actions'),
          align: 'start',
          sortable: true,
          value: 'actions'
        },
      ]
    }
  },

  methods: {
    async refresh() {
      this.jobs = await jobs.list()
    },

    async runJob (item) {
      await jobs.run(item.id)
      await this.refresh()
    },

    async refreshJobs () {
      await this.refresh()
    },

    async restartJobs () {
      await jobs.restart()
      await this.refresh()
    }
  }
}
</script>

<style scoped>

</style>
