<template>
  <resource-list
      :title="$route.meta.title"
      :icon="$route.meta.icon"
      resource="token"
      :filter="{ customer_id: customer }"
      :fields="[
          { name: 'token_id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.id') },
          { name: 'customer_id', label: this.$vuetify.lang.t('$vuetify.views.routes.common.fields.customer_id') },
          { name: 'tenant_id', label: this.$vuetify.lang.t('$vuetify.views.routes.common.fields.tenant_id') },
          { name: 'tenant.title', label: this.$vuetify.lang.t('$vuetify.views.routes.common.fields.tenant_id') },
          { name: 'value', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.value') },
          { name: 'created_date', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.created_date') },
          { name: 'expiration_date', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.expiration_date') },
        ]"
      :defaults="{
        created_date: getCurrDate,
        value: generateHash,
      }"
  />
</template>

<script>
import ResourceList from '@/components/ResourceList'
import { generateHash } from '@/utils/common'
import {mapState} from 'vuex'
import moment from 'moment'

export default {
  name: 'VTokens',
  components: { ResourceList },
  computed: {
    ...mapState('customer', ['customer']),
  },
  methods: {
    generateHash() {
      return generateHash(10)
    },

    getCurrDate() {
      return moment().format('YYYY-MM-DD')
    }
  }
}
</script>

<style scoped>

</style>
