var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('resource-list',{attrs:{"title":_vm.$route.meta.title,"icon":_vm.$route.meta.icon,"resource":"tenant","filter":{ customer_id: _vm.customer },"fields":[
        { name: 'tenant_id', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.id') },
        { name: 'title', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.title') },
        { name: 'tenancy_begin', label: _vm.$vuetify.lang.t('$vuetify.views.routes.tenants.fields.tenancy_begin') },
        { name: 'tenancy_end', label: _vm.$vuetify.lang.t('$vuetify.views.routes.tenants.fields.tenancy_end') } ],"relations":[
      { name: 'contact_contacts', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.relations.contacts_title') },
      { name: 'tokens', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.relations.tokens'), title: 'value', readOnly: true }
    ]}})}
var staticRenderFns = []

export { render, staticRenderFns }