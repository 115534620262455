<template>
  <div class="pa-5">
    <h2>{{ meter.sensor.title }} - {{ meter.title }} ({{ meter.identifier }})</h2>
    <h3 class="font-weight-thin mb-2">{{ meter.locality }}; {{ meter.description }}; {{ meter.identifier }}</h3>

    <h3 class="mb-3">
      {{ date ? date.toLocaleDateString() : null }}
    </h3>

    <v-c-b-line-chart
        v-if="loaded"
        :chart-data="chartdata"
        :options="options"
        style="height: 65vh"
    />
  </div>
</template>

<script>
import moment from 'moment'
import serie from '@/repositories/cb-server/api/serie'
import VCBLineChart, {
  defaultChartOptions,
  defaultLineSeriesOptions,
} from '@/components/charts/CBLineChart'
import {getWattsPerSecond} from '@/utils/common'
import { humanReadableValue } from '@/filters/hrv'

/**
 * @typedef {{
          labels: string[],
          datasets: {
              label: string,
              data: number[],
              backgroundColor: string[],
              borderColor: string[],
              borderWidth: Number
          }[]
        }} ChartData
 */

export default {
  name: 'VMeterMonthChart',

  components: {
    VCBLineChart,
  },

  props: {
    meter: {
      type: [Object, Array],
      default: () => ({})
    },

    date: {
      type: Date,
      default: null
    },

    filter: {
      type: Object,
      default: () => ({})
    },

    options: {
      type: Object,
      default: () => {
        const o = { ...defaultChartOptions }
        o.scales.yAxes[0].ticks.callback = label => `${humanReadableValue(label, 'W')}`
        return o
      }
    }
  },

  mounted () {
    this.refresh()
  },

  data: () => ({
    loaded: false,
    chartdata: null,
  }),

  watch: {
    meter () { this.refresh() },
    date () { this.refresh() },
    filter () { this.refresh() },
  },

  methods: {
    async refresh () {
      const response = await serie.summary({
        meter_id: this.meter.meter_id,
        date: moment(this.date).format('YYYY-MM-DD') }
      )

      if (response && response.length) {
        const r = response[0].values
        const labels = r.time.map(t => moment.unix(t).format('HH:mm'))
        const values = r.value.map(v => getWattsPerSecond(v, r.ratio, r.aggregation))

        this.chartdata = {
          labels,
          datasets: [
            {
              label: `Hourly Chart for Meter ${this.meter.meter_id}`,
              data: values,
              ...defaultLineSeriesOptions,
            }
          ]
        }

        this.loaded = true
      }
    }
  }
}
</script>

<style scoped>
</style>
