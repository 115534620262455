<template>
  <resource-list
      :title="$route.meta.title"
      :icon="$route.meta.icon"
      :filter="filter"
      resource="customer"
      :fields="[
          { name: 'customer_id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.id') },
          { name: 'title', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.title') },
        ]"
      ref="list"
  >
    <template v-slot:buttons>
      <v-btn
          v-if="canReadAny"
          color="warning"
          class="mb-2 mr-2"
          @click="toggleFilter"
          dark
      >
        {{ $vuetify.lang.t('$vuetify.common.clear_filter') }}
      </v-btn>
    </template>
  </resource-list>
</template>

<script>
import ResourceList from '@/components/ResourceList'
import {mapState} from 'vuex'
import HasRBAC from '@/mixins/HasRBAC'

export default {
  name: 'VCustomers',
  components: { ResourceList },
  mixins: [HasRBAC],
  data () {
    return {
      filterActive: true,
    }
  },
  computed: {
    ...mapState('customer', ['customer']),

    filter () {
      return this.filterActive ? { customer_id: this.customer } : null
    },

    canReadAny () {
      return this.canReadAnyResource('customer')
    },
  },
  methods: {
    toggleFilter () {
      this.filterActive = !this.filterActive
      this.$refs.list.reloadItems()
    }
  }
}
</script>

<style scoped>

</style>
