import api from './index'

export default function (resource) {
    return {
        /**
         * Fetch array of fields defs.
         * @return {Promise<{}[]>}
         */
        fields: () => api.get(`/${resource}/fields`),

        /**
         * Fetch array of resource.
         * @param where {{} | null} Where query params.
         * @return {Promise<{}[]>}
         */
        list: (where= null) => api.get(`/${resource}`, { params: where }),

        /**
         * Fetch resource item.
         * @param id {number} Id of the items.
         * @return {Promise<{}>}
         */
        read: (id) => api.get(`/${resource}/${id}`),

        /**
         * Post resource - create a new resource.
         * @param payload {{}}
         * @return {Promise<void>}
         */
        create: (payload) => api.post(`/${resource}`, payload),

        /**
         * Put resource - update existing resource.
         * @param id {number}
         * @param payload {{}}
         * @return {Promise<void>}
         */
        update: (id, payload) => api.put(`/${resource}/${id}`, payload),

        /**
         * Delete resource - delete existing resource.
         * @param id {number}
         * @return {Promise<void>}
         */
        delete: (id) => api.delete(`/${resource}/${id}`),
    }
}
