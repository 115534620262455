import cs from 'vuetify/lib/locale/cs'

export default {
    ...cs,
    common: {
        ok: 'OK',
        cancel: 'Zrušit',
        close: 'Zavřít',
        save: 'Uložit',
        new: 'Nový',
        refresh: 'Obnovit',
        clear_filter: 'Vypnout Filtr',
    },
    menu: {
        items: {
            customers: 'Zákazník',
            buildings: 'Budova',
            meters: 'Měřič',
            sensors: 'Senzor',
            reports: 'Report',
            schedules: 'Plán',
            tasks: 'Úkol',
            outputs: 'Výstup',
            generators: 'Generátor',
            users: 'Uživatel',
            contacts: 'Kontakt',
            tenants: 'Nájemník',
            jobs: 'Úloha',
            logs: 'Logy',
            tools: 'Nástroje',
            tokens: 'Token',
        },
        groups: {
            people: 'Lidé',
            network: 'Síť',
            reporting: 'Reportování',
            system: 'Systém',
        },
        profile: {
            navigation: 'Navigace',
            logout: 'Odhlásit',
        },
    },
    components: {
        resource_list: {
            using_filter: 'Aktivní filtr',
            delete_row_title: 'Smazat řádek?',
        },
        resource_edit: {
            value_title: 'Hodnota',
        },
    },
    views: {
        routes: {
            common: {
                fields: {
                    actions: 'Akce',
                    active: 'Aktivní',
                    building_id: 'Budova',
                    contact_id: 'Kontakt',
                    coords: 'Pozice',
                    created_date: 'Datum vytvoření',
                    customer_id: 'Zákazník',
                    description: 'Popis',
                    expiration_date: 'Datum expirace',
                    generator_id: 'Generátor',
                    id: 'ID',
                    installation_date: 'Datum instalace',
                    locality: 'Lokalita',
                    meta_data: 'Meta data',
                    name: 'Název',
                    output_id: 'Výstup',
                    params: 'Parametery',
                    report_id: 'Report',
                    schedule_id: 'Plán',
                    sensor_id: 'Senzor',
                    tenant_id: 'Nájemník',
                    title: 'Titulek',
                    value: 'Hodnota',
                },
                relations: {
                    contacts_title: 'Přidružené kontakty',
                    tokens: 'Vydané tokeny',
                }
            },
            buildings: {
                fields: {
                    name: 'Název (Domain Name)'
                }
            },
            generators: {
                fields: {
                    generator_type: 'Typ generátoru',
                }
            },
            contacts: {
                fields: {
                    email: 'Email',
                    first_name: 'Křestní jméno',
                    last_name: 'Příjmení',
                    phone: 'Telefon',
                },
            },
            jobs: {
                fields: {
                    last_run: 'Poslední spuštění',
                    recipe: 'CRON předpis',
                },
                buttons: {
                    schedules: 'Přejít na plán',
                    log: 'Přejít na log',
                    restart: 'Restart plánovače',
                    refresh: 'Obnovit obsah',
                },
            },
            logs: {
                fields: {
                    level: 'Úroveň',
                    message: 'Zpráva',
                    time: 'Čas',
                },
                buttons: {
                    refresh: 'Obnovit obsah',
                },
            },
            meters: {
                fields: {
                    identifier: 'Identifikátor (Číslo měřidla)',
                    ratio: 'Konverzní poměr',
                    total: 'Počáteční stav v pulzech',
                    name: 'Název (Channel Name)'
                },
                tabs: {
                    day_chart_title: 'Denní graf',
                    dashboard: 'Dashboard',
                    live_view: 'Živě!',
                    meters_title: 'Měřiče',
                    month_chart_title: 'Měsíční graf',
                },
                dashboard: {
                    refresh_meters_group: 'Obnovit Skupinu',
                    click_day_to_focus: 'Kliknutím na denní sloupec v grafu zobrazíte denní graf.',
                    total_set_button_tooltip: 'Nastavit novou celkovou spotřebu.',
                    chart_button_tooltip: 'Zobrazit graf měsíční spotřeby.',
                    edit_button_tooltip: 'Upravit vlastnosti tohoto měřiče.',
                    refresh_button_tooltip: 'Obnovit data grafu.'
                },
                live_view: {
                    title: 'Živý náhled',
                    total: 'Aktuální celkový stav (kWh)',
                    current: 'Aktuální odběr',
                    updated_at: 'Čas poslední aktualizace',
                }
            },
            outputs: {
                fields: {
                    output_type: 'Typ výstupu',
                }
            },
            schedules: {
                fields: {
                    cron_recipe: 'CRON předpis',
                },
                buttons: {
                    jobs: 'Přejít na úlohy',
                },
            },
            sensors: {
                fields: {
                    sensor_type: 'Typ senzoru',
                    mac_address: 'MAC adresa',
                    ip_address: 'IP adresa',
                    name: 'Název (Box Name)'
                }
            },
            tenants: {
                fields: {
                    tenancy_begin: 'Začátek smlouvy',
                    tenancy_end: 'Konec smlouvy',
                }
            },
            tools: {
                fields: {
                    id: 'ID (Sensor.name)',
                    pac: 'PAC',
                    sequence_number: 'Číslo sekvence',
                    last_comm: 'Poslední komunikace',
                    activation_time: 'Čas aktivace',
                },
            },
            users: {
                fields: {
                    user_name: 'Uživatelské jméno',
                    role: 'Role'
                },
            },
            tokens: {
                fields: {
                    token: 'Token',
                },
            },
        },
        login: {
            title: 'CleverBoard API',
            subtitle: 'Prosím zadejte platné uživatelské jméno a heslo!',
            user_name: 'Uživatelské jméno',
            password: 'Heslo',
            login: 'Přihlásit',
        },
        view: {
            customer_select_title: 'Zákazník',
        }
    },
    tenant: {
        meters: {
            total_title: 'Stav měřidla',
            day_consumption_title: 'Dnešní spotřeba',
            week_consumption_title: 'Týdenní spotřeba',
            month_consumption_title: 'Měsíční spotřeba',
            year_consumption_title: 'Roční spotřeba',
            identifier_label: 'Číslo měřidla',
            locality_label: 'Lokalita',
            description_label: 'Popis',
            day_label: 'den',
            week_label: 'týden',
            month_label: 'měsíc',
            year_label: 'rok',
            day_tab: 'Den',
            week_tab: 'Týden',
            month_tab: 'Měsíc',
            year_tab: 'Rok',
            day_series_title: 'Denní hodnoty měřiče',
            week_series_title: 'Týdenní hodnoty měřiče',
            month_series_title: 'Měsíční hodnoty měřiče',
            year_series_title: 'Roční hodnoty měřiče',
            updated_at: 'aktualizováno',
        }
    }
}
