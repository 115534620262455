import api from './index'

export default {
    list () {
        return api.get(`/jobs`)
    },

    run (id) {
        return api.post(`/jobs/run`, { id })
    },

    restart () {
        return api.post('/jobs/restart')
    }
}
