<template>
  <div>
    <v-card>
      <v-card-text>
        <v-toolbar-title>
          PASSWORD TOOLS
        </v-toolbar-title>
        <v-text-field
            type="text"
            name="username"
            label="Username"
            placeholder="johndoe"
            required
            v-model="username"
        />
        <v-text-field
            type="password"
            name="password"
            label="Password"
            required
            v-model="password"
        />
        <v-btn
            class="mr-2"
            color="primary"
            @click="generate"
        >
          Generate Password Hash
        </v-btn>

        <v-btn
            class="mr-2"
            color="error"
            @click="login"
        >
          Fetch Token
        </v-btn>

        <pre class="mt-5 pa-5 grey">{{ result || 'no data' }}</pre>
      </v-card-text>
    </v-card>

    <v-card class="mt-2">
      <v-card-text class="pa-0 pb-1">
        <v-data-table
            :headers="sigfoxHeaders"
            :items="sigfoxDevices"
        >
          <template v-slot:top>
            <v-toolbar
                flat
            >
              <v-toolbar-title>SIGFOX DEVICES</v-toolbar-title>

              <v-spacer/>

              <v-btn
                  class="mr-2"
                  color="primary"
                  @click="fetchSigfoxDevices"
              >
                Fetch Devices
              </v-btn>
            </v-toolbar>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="importSigfoxDevice(item)"
            >
              mdi-import
            </v-icon>
          </template>
        </v-data-table>
        <pre class="ma-3 pa-5 grey">{{ sigfoxDevices || 'no data' }}</pre>
      </v-card-text>
    </v-card>

    <v-card class="mt-2">
      <v-card-text>
        <v-toolbar-title>
          SERIES TOOLS
        </v-toolbar-title>
        <v-text-field
            type="text"
            name="meter_id"
            label="Meter Id"
            required
            v-model="seriesMeterId"
        />
        <v-btn
            class="mr-2"
            color="primary"
            @click="seriesDialog = true"
        >
          Remove Series
        </v-btn>

        <v-dialog
            v-model="seriesDialog"
            width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Delete series?
            </v-card-title>

            <v-card-text>
              Please confirm delete series with meterId={{ seriesMeterId }}!
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="secondary"
                  text
                  @click="seriesDialog = false"
              >
                Cancel
              </v-btn>

              <v-btn
                  color="primary"
                  text
                  @click="removeSeriesByMeterId"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <pre class="mt-5 pa-5 grey">{{ seriesResult || 'no data' }}</pre>
      </v-card-text>
    </v-card>

    <v-card class="mt-2">
      <v-card-text>
        <v-toolbar-title>
          INFLUX SERIES TOOLS
        </v-toolbar-title>
        <v-text-field
            type="text"
            name="location"
            label="Sensor Location"
            required
            v-model="influxSeriesLocation"
        />
        <v-text-field
            type="text"
            name="name"
            label="Sensor Name"
            required
            v-model="influxSeriesName"
        />
        <v-text-field
            type="text"
            name="output"
            label="Sensor Output"
            required
            v-model="influxSeriesOutput"
        />
        <v-btn
            class="mr-2"
            color="primary"
            @click="influxSeriesDialog = true"
        >
          Remove Influx Series
        </v-btn>

        <v-btn
            class="mr-2"
            color="primary"
            @click="getInfluxSeriesCountByLocationNameOutput()"
        >
          Get Influx Series
        </v-btn>


        <v-dialog
            v-model="influxSeriesDialog"
            width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Delete influx series?
            </v-card-title>

            <v-card-text>
              Please confirm delete Influx series with location={{ influxSeriesLocation }}, name={{ influxSeriesName }}, output={{ influxSeriesOutput }}!
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="secondary"
                  text
                  @click="influxSeriesDialog = false"
              >
                Cancel
              </v-btn>

              <v-btn
                  color="primary"
                  text
                  @click="removeInfluxSeriesByLocationNameOutput"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <pre class="mt-5 pa-5 grey">{{ influxSeriesResult || 'no data' }}</pre>
      </v-card-text>
    </v-card>


    <v-card class="mt-2">
        <v-card-text>
          <v-toolbar-title>
            GRANTS
          </v-toolbar-title>
          <pre>{{ grants }}</pre>
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
import auth from '@/repositories/cb-server/api/auth'
import sigfox from '@/repositories/cb-server/api/sigfox'
import serie from '@/repositories/cb-server/api/serie'
import { mapState } from 'vuex'

export default {
  name: 'VSandbox',

  data () {
    return {
      username: '',
      password: '',
      result: '',
      sigfoxDevices: [],
      seriesMeterId: null,
      seriesResult: null,
      seriesDialog: false,
      influxSeriesLocation: null,
      influxSeriesName: null,
      influxSeriesOutput: null,
      influxSeriesDialog: false,
      influxSeriesResult: null,
    }
  },

  computed: {
    ...mapState('rbac', ['grants']),

    sigfoxHeaders () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.views.routes.tools.fields.id'),
          align: 'start',
          sortable: true,
          value: 'id'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.views.routes.common.fields.name'),
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.views.routes.tools.fields.pac'),
          align: 'start',
          sortable: true,
          value: 'pac'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.views.routes.tools.fields.sequence_number'),
          align: 'start',
          sortable: true,
          value: 'sequenceNumber'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.views.routes.tools.fields.last_comm'),
          align: 'start',
          sortable: true,
          value: 'lastCom'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.views.routes.tools.fields.activation_time'),
          align: 'start',
          sortable: true,
          value: 'activationTime'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.views.routes.common.fields.actions'),
          align: 'start',
          sortable: true,
          value: 'actions'
        },
      ]
    }
  },

  methods: {
    async generate () {
      try {
        this.result = await auth.generate(this.username, this.password)
      } catch (e) {
        this.result = e
      }
    },

    async login () {
      try {
        this.result = await auth.login(this.username, this.password)
      } catch (e) {
        this.result = e
      }
    },

    async importSigfoxDevice (device) {
      return device
    },

    async fetchSigfoxDevices () {
      this.sigfoxDevices = await sigfox.devices()
      this.sigfoxDevices = this.sigfoxDevices.map(device => {
        device.lastCom = (new Date(device.lastCom)).toISOString()
        device.activationTime = (new Date(device.activationTime)).toISOString()
        return device
      })
    },

    async removeSeriesByMeterId () {
      try {
        this.seriesResult = await serie.deleteByMeterId(this.seriesMeterId)
      } catch (e) {
        this.seriesResult = e
      }

      this.seriesDialog = false
    },

    async removeInfluxSeriesByLocationNameOutput () {
      try {
        this.influxSeriesResult = await serie.deleteInfluxSeries(
            this.influxSeriesLocation,
            this.influxSeriesName,
            this.influxSeriesOutput,
        )
      } catch (e) {
        this.influxSeriesResult = e
      }

      this.influxSeriesDialog = false
    },

    async getInfluxSeriesCountByLocationNameOutput () {
      try {
        this.influxSeriesResult = await serie.getSensorDataCount(
            this.influxSeriesLocation,
            this.influxSeriesName,
            this.influxSeriesOutput,
        )
      } catch (e) {
        this.influxSeriesResult = e
      }

      this.influxSeriesDialog = false
    }
  }
}
</script>

<style scoped>

</style>
