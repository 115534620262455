import rbac from '@/services/rbac'

export default {
  methods: {
    canReadResource (resource) {
      return rbac.check(['readAny', 'readOwn'], resource)
    },

    canUpdateResource (resource) {
      return rbac.check(['updateAny', 'updateOwn'], resource)
    },

    canCreateResource (resource) {
      return rbac.check(['createAny', 'createOwn'], resource)
    },

    canDeleteResource (resource) {
      return rbac.check(['deleteAny', 'deleteOwn'], resource)
    },

    canReadAnyResource (resource) {
      return rbac.check(['readAny'], resource)
    },

    canUpdateAnyResource (resource) {
      return rbac.check(['updateAny'], resource)
    },

    canCreateAnyResource (resource) {
      return rbac.check(['createAny'], resource)
    },

    canDeleteAnyResource (resource) {
      return rbac.check(['deleteAny'], resource)
    },

    canReadOwnResource (resource) {
      return rbac.check(['readOwn'], resource)
    },

    canUpdateOwnResource (resource) {
      return rbac.check(['updateOwn'], resource)
    },

    canCreateOwnResource (resource) {
      return rbac.check(['createOwn'], resource)
    },

    canDeleteOwnResource (resource) {
      return rbac.check(['deleteOwn'], resource)
    },
  },
}
