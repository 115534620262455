import en from 'vuetify/lib/locale/en'

export default {
    ...en,
    common: {
        ok: 'OK',
        cancel: 'Cancel',
        close: 'Close',
        save: 'Save',
        new: 'New',
        refresh: 'Refresh',
        clear_filter: 'Vypnout Filtr',
    },
    menu: {
        items: {
            customers: 'Customers',
            buildings: 'Buildings',
            meters: 'Meters',
            sensors: 'Sensors',
            reports: 'Reports',
            schedules: 'Schedules',
            tasks: 'Tasks',
            outputs: 'Outputs',
            generators: 'Generators',
            users: 'Users',
            contacts: 'Contacts',
            tenants: 'Tenants',
            jobs: 'Jobs',
            logs: 'Logs',
            tools: 'Tools',
            tokens: 'Token',
        },
        groups: {
            people: 'People',
            network: 'Network',
            reporting: 'Reporting',
            system: 'System',
        },
        profile: {
            navigation: 'Navigation',
            logout: 'Logout',
        },
    },
    components: {
        resource_list: {
            using_filter: 'Using Filter',
            delete_row_title: 'Delete row?',
        },
        resource_edit: {
            value_title: 'Value',
        },
    },
    views: {
        routes: {
            common: {
                fields: {
                    actions: 'Actions',
                    active: 'Active',
                    building_id: 'Building',
                    contact_id: 'Contact',
                    coords: 'Coords',
                    created_date: 'Created Date',
                    customer_id: 'Customer',
                    description: 'Description',
                    expiration_date: 'Expiration Date',
                    generator_id: 'Generator',
                    id: 'ID',
                    installation_date: 'Installation Date',
                    locality: 'Locality',
                    meta_data: 'Meta Data',
                    name: 'Name',
                    output_id: 'Output',
                    params: 'Parameters',
                    report_id: 'Report',
                    schedule_id: 'Schedule',
                    sensor_id: 'Sensor',
                    tenant_id: 'Tenant',
                    title: 'Title',
                    value: 'Value',
                },
                relations: {
                    contacts_title: 'Related Contacts',
                    tokens: 'Issued Tokens',
                }
            },
            buildings: {
                fields: {
                    name: 'Name (Domain Name)'
                }
            },
            generators: {
                fields: {
                    generator_type: 'Generator Type',
                }
            },
            contacts: {
                fields: {
                    email: 'Email',
                    first_name: 'First Name',
                    last_name: 'Last Name',
                    phone: 'Phone',
                },
            },
            jobs: {
                fields: {
                    last_run: 'Last Run',
                    recipe: 'Recipe',
                },
                buttons: {
                    schedules: 'Show Schedules',
                    log: 'Show Log',
                    restart: 'Restart',
                    refresh: 'Refresh',
                },
            },
            logs: {
                fields: {
                    level: 'Level',
                    message: 'Message',
                    time: 'Time',
                },
                buttons: {
                    refresh: 'Obnovit obsah',
                },
            },
            meters: {
                fields: {
                    identifier: 'Identifier (Meter Code)',
                    ratio: 'Conversion Ratio',
                    total: 'Starting State in Pulses',
                    name: 'Name (Channel Name)'
                },
                tabs: {
                    day_chart_title: 'Day Chart',
                    dashboard: 'Dashboard',
                    live_view: 'Live!',
                    meters_title: 'Meters',
                    month_chart_title: 'Month Chart',
                },
                dashboard: {
                    refresh_meters_group: 'Refresh Group',
                    click_day_to_focus: 'Click on a day bar in chart to view day chart.',
                    total_set_button_tooltip: 'Set new total consumption value.',
                    chart_button_tooltip: 'Show monthly chart for this meter.',
                    edit_button_tooltip: 'Edit properties of this meter.',
                    refresh_button_tooltip: 'Refresh chart data.',
                    no_enough_rights: 'You have not enought rights to view the meters dashboard!',
                },
                live_view: {
                    title: 'Live View',
                    total: 'Current Overall State (kWh)',
                    current: 'Current Consumption',
                    updated_at: 'Last Update Time',
                }
            },
            outputs: {
                fields: {
                    output_type: 'Output Type',
                }
            },
            schedules: {
                fields: {
                    cron_recipe: 'CRON Recipe',
                },
                buttons: {
                    jobs: 'Show Jobs',
                },
            },
            sensors: {
                fields: {
                    sensor_type: 'Sensor Type',
                    mac_address: 'MAC Address',
                    ip_address: 'IP Address',
                    name: 'Name (Box Name)'
                }
            },
            tenants: {
                fields: {
                    tenancy_begin: 'Tenancy Begin',
                    tenancy_end: 'Tenancy End',
                }
            },
            tools: {
                fields: {
                    id: 'ID (Sensor.name)',
                    pac: 'PAC',
                    sequence_number: 'Sequence Number',
                    last_comm: 'Last Comm',
                    activation_time: 'Activation Time',
                },
            },
            users: {
                fields: {
                    user_name: 'User Name',
                    role: 'Role'
                },
            },
            tokens: {
                fields: {
                    token: 'Token',
                },
            },
        },
        login: {
            title: 'CleverBoard API',
            subtitle: 'Please enter valid user name and password!',
            user_name: 'User Name',
            password: 'Password',
            login: 'Login',
        },
        view: {
            customer_select_title: 'Customer',
        }
    },
    tenant: {
        meters: {
            total_title: "Meter Value",
            day_consumption_title: "Today Consumption",
            week_consumption_title: "Týdenní spotřeba",
            month_consumption_title: "This Month Consumption",
            year_consumption_title: "This Year Consumption",
            identifier_label: 'Identifier',
            locality_label: 'Locality',
            description_label: 'Description',
            day_label: 'day',
            week_label: 'week',
            month_label: 'month',
            year_label: 'year',
            day_tab: "Day",
            week_tab: "Week",
            month_tab: "Month",
            year_tab: "Year",
            day_series_title: 'Daily values of meter',
            week_series_title: 'Weekly values of meter',
            month_series_title: 'Monthly values of meter',
            year_series_title: 'Yearly values of meter',
            updated_at: 'updated at',
        }
    }
}
