import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

import en from '../lang/en'
import cs from '../lang/cs'
import theme from '@/config/theme'
import colors from 'vuetify/lib/util/colors'

const vuetify = new Vuetify({
    icons: {
        iconfont: 'fa',
    },
    lang: {
        locales: { en, cs },
        current: 'cs',
    },
    theme: {
        themes: {
            light: {
                primary: colors[theme.color],
            },
            dark: {
                primary: colors[theme.color],
            }
        }
    }
});

export default vuetify

export const $t = vuetify.framework.lang.t.bind(vuetify.framework.lang)
