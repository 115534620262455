import Vue from 'vue'
import Vuex from 'vuex'
import { account } from './account'
import { message } from './message'
import { customer } from './customer'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    account,
    message,
    customer
  },
})

export default store
