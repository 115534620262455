import axios from 'axios'
import store from '@/store'
import config from '@/config'

const api = axios.create({
    baseURL: config.api.host,
})

api.interceptors.request.use(
    config => {
        const authorization = store.state.account.authorization

        if (authorization?.accessToken) {
            config.headers = {
                'Authorization': `${authorization?.tokenType} ${authorization.accessToken}`
            }
        }

        return config
    }
)

api.interceptors.response.use(
    response => {
        if (response.status < 300) {
            return response.data.data
        }
    },
    error => {
        if (error.response) {
            if (error.response.status === 403) {
                switch (error.response.data.id) {
                  case 2003: store.dispatch('message/error', 'Invalid password!'); break
                  case 2004: store.dispatch('message/error', 'Invalid token! Logging out...');  store.commit('account/logout'); break
                  case 2005: store.dispatch('message/error', 'Token has already expired! Logging out...');  store.commit('account/logout'); break
                  case 2006: store.dispatch('message/error', 'Access denied!'); break
                  case 2007: store.dispatch('message/error', 'Forbidden resource!'); break
                  default: store.dispatch('message/error', `${error.response.data.id}: ${error.response.data.message}`)
                }
            } else {
                store.dispatch('message/error', error.response.message)
            }
        } else {
            store.dispatch('message/error', 'Unknown response error!')
        }

        return Promise.reject(error.response ? error.response.data : error)
    }
)

export default api
