<template>
  <v-main>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card elevation="24">
            <v-toolbar dark :color="color">
              <v-toolbar-title><v-icon>fa-microchip</v-icon> {{ $vuetify.lang.t('$vuetify.views.login.title') }}</v-toolbar-title>
            </v-toolbar>
            <v-card-subtitle class="pt-10">{{ $vuetify.lang.t('$vuetify.views.login.subtitle') }}</v-card-subtitle>
            <v-card-text class="px-6">
              <v-form>
                <v-text-field
                    v-model="username"
                    prepend-icon="fa-user"
                    name="username"
                    :label="$vuetify.lang.t('$vuetify.views.login.user_name')"
                    :persistent-placeholder="true"
                    placeholder=" "
                    autofocus
                    type="text"
                ></v-text-field>
                <v-text-field
                    v-model="password"
                    id="password"
                    prepend-icon="fa-lock"
                    name="password"
                    :label="$vuetify.lang.t('$vuetify.views.login.password')"
                    :persistent-placeholder="true"
                    placeholder=" "
                    type="password"
                    v-on:keyup.enter="login"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions class="px-6 pb-6">
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="login" large>{{ $vuetify.lang.t('$vuetify.views.login.login') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import theme from '@/config/theme'

export default {
  name: 'VLogin',
  computed: {
    color() {
      return theme.color
    }
  },
  data () {
    return {
      username: null,
      password: null,
    }
  },
  methods: {
    login () {
      this.$store.dispatch('account/login', {
        username: this.username,
        password: this.password
      })
    }
  }
}
</script>

<style scoped>

</style>
