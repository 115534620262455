import { AccessControl } from 'accesscontrol'

export default {

  /**
   * Intialize AccessControl according to given grants and role.
   * @param grants
   * @param role
   */
  init (grants, role) {
    this.ac = grants ? new AccessControl(grants) : null
    this.can = this.ac ? this.ac.can(role) : null
  },

  deinit () {
    this.ac = null
    this.can = null
  },

  /**
   * Tests action/resource combination against RBAC service.
   * @param action { ('readOwn' | 'readAny' | 'updateOwn' | 'updateAny' | 'updateOwn' |
   * 'updateAny' | 'createOwn' | 'createAny' | 'deleteOwn' | 'deleteAny')[] } RBAC action.
   * @param resource { string }
   * @return { boolean }
   */
  check (action, resource) {
    return this.can ? action.some(a => this.can[a](resource).granted) : false
  }

}
