var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{attrs:{"background-color":"primary","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.views.routes.meters.tabs.meters_title'))+" ")]),_c('v-tab',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.views.routes.meters.tabs.dashboard'))+" ")]),_c('v-tab',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.views.routes.meters.tabs.live_view'))+" ")]),_c('v-tab',{attrs:{"disabled":!_vm.meter}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.views.routes.meters.tabs.month_chart_title'))+" ")]),_c('v-tab',{attrs:{"disabled":!_vm.meter || !_vm.date}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.views.routes.meters.tabs.day_chart_title'))+" ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-resource-list',{ref:"list",attrs:{"resource":"meter","title":_vm.$route.meta.title,"icon":_vm.$route.meta.icon,"filter":{ customer_id: _vm.customer },"fields":[
        { name: 'meter_id', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.id') },
        { name: 'customer_id', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.customer_id'), visible: { list: false, edit: true } },
        { name: 'sensor_id', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.sensor_id'), visible: { list: false, edit: true } },
        { name: 'tenant_id', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.tenant_id'), visible: { list: false, edit: true } },
        { name: 'identifier', label: _vm.$vuetify.lang.t('$vuetify.views.routes.meters.fields.identifier') },
        { name: 'locality', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.locality') },
        { name: 'name', label: _vm.$vuetify.lang.t('$vuetify.views.routes.meters.fields.name'), visible: { list: false, edit: true } },
        { name: 'title', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.title') },
        { name: 'ratio', label: _vm.$vuetify.lang.t('$vuetify.views.routes.meters.fields.ratio'), visible: { list: false, edit: true } },
        { name: 'total', label: _vm.$vuetify.lang.t('$vuetify.views.routes.meters.fields.total'), visible: { list: false, edit: true } },
        { name: 'installation_date', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.installation_date') },
        { name: 'description', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.description') } ],"group-by":[
          'sensor.title'
      ],"group-title":[
          _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.sensor_id')
      ],"forbid-edit-fields":[
        'series'
      ]}})],1),(_vm.canReadSeries)?_c('v-tab-item',[_c('v-meters-dashboard',{attrs:{"meter":_vm.meter},on:{"meter:edit":_vm.meterEdit,"meter:chart":_vm.meterChart,"meter:total":_vm.meterTotal}})],1):_vm._e(),(_vm.canReadSeries)?_c('v-tab-item',[_c('v-meters-live-view',{attrs:{"meter":_vm.meter}})],1):_vm._e(),(_vm.canReadSeries)?_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-meter-month-chart',{attrs:{"meter":_vm.meter,"date":_vm.date},on:{"day:click":_vm.monthDayClick}})],1)],1):_vm._e(),(_vm.canReadSeries)?_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-meter-day-chart',{attrs:{"meter":_vm.meter,"date":_vm.date}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }