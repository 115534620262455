<template>
  <v-card>
    <v-data-table
        :headers="headers"
        :items="log"
        :items-per-page="100"
        sort-by="time"
        sort-desc="true"
        dense
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-icon v-if="$route.meta.icon" class="mb-1 mr-2">{{ $route.meta.icon }}</v-icon>
          <v-toolbar-title>{{ $route.meta.title.toUpperCase() }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="refresh"
          >
            {{ $vuetify.lang.t('$vuetify.views.routes.logs.buttons.refresh') }}
          </v-btn>
        </v-toolbar>
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import logs from '@/repositories/cb-server/api/logs'

export default {
  name: 'VLogs',

  data: () => ({
    log: [],
  }),

  mounted() {
    this.refresh()
  },

  computed: {
    headers () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.views.routes.logs.fields.time'),
          align: 'start',
          sortable: true,
          value: 'time'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.views.routes.logs.fields.level'),
          align: 'start',
          sortable: true,
          value: 'level'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.views.routes.logs.fields.message'),
          align: 'start',
          sortable: true,
          value: 'message'
        },
      ]
    }
  },

  methods: {
    async refresh() {
      let items = await logs.list()

      items = items.map(item => item.split(`\t`))
      this.log = items.map(
          ([time, level, message]) => ({
            time, level, message
          })
      )
    }
  }
}
</script>

<style scoped>

</style>
