import api from './index'

export default {
    grants () {
        return api.get(`/rbac/grants`)
    },

    roles () {
        return api.get(`/rbac/roles`)
    },
}
