var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('resource-list',{attrs:{"title":_vm.$route.meta.title,"icon":_vm.$route.meta.icon,"resource":"sensor","filter":{ customer_id: _vm.customer },"fields":[
        { name: 'sensor_id', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.id') },
        { name: 'customer_id', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.customer_id'), visible: { list: false, edit: true } },
        { name: 'building_id', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.building_id'), visible: { list: false, edit: true } },
        { name: 'sensor_type_id', label: _vm.$vuetify.lang.t('$vuetify.views.routes.sensors.fields.sensor_type'), visible: { list: false, edit: true } },
        { name: 'name', label: _vm.$vuetify.lang.t('$vuetify.views.routes.sensors.fields.name') },
        { name: 'title', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.title') },
        { name: 'location', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.locality') },
        { name: 'description', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.description'), visible: { list: false, edit: true } },
        { name: 'installation_date', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.installation_date') },
        { name: 'mac', label: _vm.$vuetify.lang.t('$vuetify.views.routes.sensors.fields.mac_address') },
        { name: 'ip', label: _vm.$vuetify.lang.t('$vuetify.views.routes.sensors.fields.ip_address') },
        { name: 'metadata', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.meta_data'), visible: { list: false, edit: true } },
        { name: 'coords', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.coords'), visible: { list: false, edit: true } } ],"group-by":[
          'building.title'
      ],"group-title":[
          _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.building_id')
      ]}})}
var staticRenderFns = []

export { render, staticRenderFns }