<template>
  <div>
    <v-text-field
        v-bind="$attrs"
        :append-icon="'mdi-map-marker'"
        clear-icon="mdi-close-circle"
        clearable
        type="text"
        :value="coords"
        @change="$emit('input', $event)"
        @click:append="pickCoords"
    >
    </v-text-field>
    <v-dialog
        v-if="mapDialog"
        v-model="mapDialog"
        max-width="1000px"
    >
      <l-map
          ref="map"
          style="height: 600px"
          :center="center"
          :zoom="zoom"
          @click="mapClick"
          @ready="mapReady"
      >
        <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></l-tile-layer>
        <l-marker v-if="coords" :lat-lng="coords"></l-marker>
      </l-map>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'VCoordsPicker',

  props: {
    value: {
      type: Object,
      default: null
    },
  },

  data: () => ({
    mapDialog: false,
  }),

  computed: {
    coords () {
      return this.value?.coordinates
    },

    center () {
      return this.coords || [50.082216155163614,14.438919065287338]
    },

    zoom () {
      return this.coords ? 15 : 12
    }
  },

  methods: {
    pickCoords() {
      this.mapDialog = true
    },

    mapReady(a) {
      a.invalidateSize()
    },

    mapClick(e) {
      const latLng = e.latlng
      this.$emit('input', { type: 'Point', coordinates: [latLng.lat, latLng.lng] })
    }
  }
}
</script>

<style scoped>

</style>
