<template>
  <resource-list
      :title="$route.meta.title"
      :icon="$route.meta.icon"
      resource="tenant"
      :filter="{ customer_id: customer }"
      :fields="[
          { name: 'tenant_id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.id') },
          { name: 'title', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.title') },
          { name: 'tenancy_begin', label: $vuetify.lang.t('$vuetify.views.routes.tenants.fields.tenancy_begin') },
          { name: 'tenancy_end', label: $vuetify.lang.t('$vuetify.views.routes.tenants.fields.tenancy_end') },
        ]"
      :relations="[
        { name: 'contact_contacts', label: $vuetify.lang.t('$vuetify.views.routes.common.relations.contacts_title') },
        { name: 'tokens', label: $vuetify.lang.t('$vuetify.views.routes.common.relations.tokens'), title: 'value', readOnly: true }
      ]"

  />
</template>

<script>
import ResourceList from '@/components/ResourceList'
import {mapState} from 'vuex'

export default {
  name: 'VTenants',
  components: { ResourceList },
  computed: {
    ...mapState('customer', ['customer']),
  },
}
</script>

<style scoped>

</style>
