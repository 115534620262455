<template>
  <v-app :style="{ 'background-color': color }">
    <v-tenant-view v-if="hasToken" />
    <v-admin-view v-else />
  </v-app>
</template>

<script>
import theme from '@/config/theme'
import colors from 'vuetify/lib/util/colors'
import VTenantView from './views/TenantView'
import VAdminView from './views/AdminView'
import HasQueryParams from '@/mixins/HasQueryParams'

export default {
  name: 'App',
  components: { VAdminView, VTenantView },
  mixins: [HasQueryParams],
  computed: {
    hasToken () {
      return this.queryParams.get('token')
    },

    color() {
      return colors[theme.color].darken4
    },
  }
}
</script>

<style lang="scss">

</style>
