<template>
  <div class="pa-5">
    <h2>{{ meter.sensor.title }} - {{ meter.title }} ({{ meter.identifier }})</h2>
    <h3 class="font-weight-thin mb-2">{{ meter.locality }}; {{ meter.description }}; {{ meter.identifier }}</h3>
    <v-alert v-if="!date" dense type="info" elevation="5" dismissible>
      {{ $vuetify.lang.t('$vuetify.views.routes.meters.dashboard.click_day_to_focus') }}
    </v-alert>
    <v-cb-barchart
          v-if="loaded"
          :chart-data="chartdata"
          :options="options"
          style="height: 65vh"
          @chart:click="chartClick"
      />
  </div>
</template>

<script>
import moment from 'moment'
import serie from '@/repositories/cb-server/api/serie'
import CBBarChart, {defaultBarSeriesOptions, defaultChartOptions} from '@/components/charts/CBBarChart'
import { humanReadableValue } from '@/filters/hrv'

/**
 * @typedef {{
          labels: string[],
          datasets: {
              label: string,
              data: number[],
              backgroundColor: string[],
              borderColor: string[],
              borderWidth: Number
          }[]
        }} ChartData
 */

export default {
  name: 'VMeterMonthChart',

  components: {
    'v-cb-barchart': CBBarChart
  },

  props: {
    meter: {
      type: [Object, Array],
      default: () => ({})
    },

    date: {
      type: Date,
      default: null
    },

    filter: {
      type: Object,
      default: () => ({})
    },

    options: {
      type: Object,
      default: () => {
        const o = { ...defaultChartOptions }
        o.scales.yAxes[0].ticks.callback = label => `${humanReadableValue(label * 1000, 'Wh')}`
        return o
      }
    }
  },

  mounted () {
    this.refresh()
  },

  data: () => ({
    loaded: false,
    chartdata: null,
  }),

  watch: {
    meter () { this.refresh() },
    filter () { this.refresh() },
  },

  methods: {
    async refresh () {
      const response = await serie.summary({ meter_id: this.meter.meter_id })

      if (response) {
        response.splice(0, response.length - 31)

        const times = response.map(serie => serie.date)
        const labels = response.map(serie => moment(serie.date).toDate().toLocaleDateString())
        const values = response.map(serie => serie.sum / serie.values.ratio)

        this.chartdata = {
          labels,
          datasets: [
            {
              label: `Daily Sum for Meter ${this.meter.meter_id}`,
              times: times,
              data: values,
              ...defaultBarSeriesOptions,
            }
          ]
        }

        this.loaded = true
      }
    },

    chartClick (dataset, index) {
      this.$emit('day:click', dataset.times[index])
    },
  }
}
</script>

<style scoped>
</style>
