var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('resource-list',{attrs:{"title":_vm.$route.meta.title,"icon":_vm.$route.meta.icon,"resource":"token","filter":{ customer_id: _vm.customer },"fields":[
        { name: 'token_id', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.id') },
        { name: 'customer_id', label: this.$vuetify.lang.t('$vuetify.views.routes.common.fields.customer_id') },
        { name: 'tenant_id', label: this.$vuetify.lang.t('$vuetify.views.routes.common.fields.tenant_id') },
        { name: 'tenant.title', label: this.$vuetify.lang.t('$vuetify.views.routes.common.fields.tenant_id') },
        { name: 'value', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.value') },
        { name: 'created_date', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.created_date') },
        { name: 'expiration_date', label: _vm.$vuetify.lang.t('$vuetify.views.routes.common.fields.expiration_date') } ],"defaults":{
      created_date: _vm.getCurrDate,
      value: _vm.generateHash,
    }}})}
var staticRenderFns = []

export { render, staticRenderFns }