<template>
  <resource-list
      :title="$route.meta.title"
      :icon="$route.meta.icon"
      resource="task"
      :filter="{ customer_id: customer }"
      :fields="[
          { name: 'task_id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.id') },
          { name: 'customer_id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.customer_id'), visible: { list: false, edit: true } },
          { name: 'report_id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.report_id'), visible: { list: false, edit: true } },
          { name: 'schedule_id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.schedule_id'), visible: { list: false, edit: true } },
          { name: 'output_id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.output_id'), visible: { list: false, edit: true } },
          { name: 'title', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.title') },
          { name: 'report.title', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.report_id'), visible: { list: true, edit: false } },
          { name: 'schedule.title', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.schedule_id'), visible: { list: true, edit: false } },
          { name: 'output.title', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.output_id'), visible: { list: true, edit: false } },
      ]"
      :relations="[
        { name: 'contact_id_contacts', label: $vuetify.lang.t('$vuetify.views.routes.common.relations.contacts_title') }
      ]"
  />
</template>

<script>
import ResourceList from '@/components/ResourceList'
import {mapState} from 'vuex'

export default {
  name: 'VTasks',
  components: { ResourceList },
  computed: {
    ...mapState('customer', ['customer']),
  },
}
</script>

<style scoped>

</style>
