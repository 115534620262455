<template>
  <div class="ma-0 pa-0">
    <div class="ma-2 pa-0">
      <v-card-title class="mb-2" style="display:block">
        <span class="grey--text font-weight-thin">{{ $vuetify.lang.t('$vuetify.tenant.meters.identifier_label') }}:</span>&nbsp;{{ meter.identifier || meter.title }}
      </v-card-title>
      <v-card-subtitle>
        <span class="grey--text font-weight-thin">{{ $vuetify.lang.t('$vuetify.tenant.meters.locality_label') }}:</span>&nbsp;{{ meter.locality }}
        <span class="grey--text font-weight-thin">, </span>
        <span class="grey--text font-weight-thin">{{ $vuetify.lang.t('$vuetify.tenant.meters.description_label') }}:</span>&nbsp;{{ meter.description }}
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col sm="3">
            <v-text-field
                :label="$vuetify.lang.t('$vuetify.tenant.meters.total_title')"
                :value="energy.total.toFixed(2) + ' kWh'"
                readonly
            />
            <v-text-field
                :label="$vuetify.lang.t('$vuetify.tenant.meters.day_consumption_title')"
                :value="energy.day.toFixed(2) + ' kWh'"
                readonly
            />
            <v-text-field
                :label="$vuetify.lang.t('$vuetify.tenant.meters.week_consumption_title')"
                :value="energy.week.toFixed(2) + ' kWh'"
                readonly
            />
            <v-text-field
                :label="$vuetify.lang.t('$vuetify.tenant.meters.month_consumption_title')"
                :value="energy.month.toFixed(2) + ' kWh'"
                readonly
            />
            <v-text-field
                :label="$vuetify.lang.t('$vuetify.tenant.meters.year_consumption_title')"
                :value="energy.year.toFixed(2) + ' kWh'"
                readonly
            />
          </v-col>

          <v-col sm="9">
            <v-tabs v-model="tab" centered show-arrows>
              <v-tab><small><v-icon small>fa-chart-line</v-icon>&nbsp;{{ $vuetify.lang.t('$vuetify.tenant.meters.day_tab')}}</small></v-tab>
              <v-tab><small><v-icon small>fa-chart-column</v-icon>&nbsp;{{ $vuetify.lang.t('$vuetify.tenant.meters.week_tab')}}</small></v-tab>
              <v-tab><small><v-icon small>fa-chart-column</v-icon>&nbsp;{{ $vuetify.lang.t('$vuetify.tenant.meters.month_tab')}}</small></v-tab>
              <v-tab><small><v-icon small>fa-chart-column</v-icon>&nbsp;{{ $vuetify.lang.t('$vuetify.tenant.meters.year_tab')}}</small></v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-c-b-line-chart
                    :chart-data="series.day"
                    :options="lineChartOptions"
                    style="height: 50vh"
                />
              </v-tab-item>

              <v-tab-item>
                <v-c-b-bar-chart
                    :chart-data="series.week"
                    :options="barChartOptions"
                    style="height: 50vh"
                    @chart:click="chartClick"
                />
              </v-tab-item>

              <v-tab-item>
                <v-c-b-bar-chart
                    :chart-data="series.month"
                    :options="barChartOptions"
                    style="height: 50vh"
                    @chart:click="chartClick"
                />
              </v-tab-item>

              <v-tab-item>
                <v-c-b-bar-chart
                    :chart-data="series.year"
                    :options="barChartOptions"
                    style="height: 50vh"
                    @chart:click="chartClick"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { getWattsPerSecond } from '@/utils/common'
import VCBLineChart, {
  defaultSmallChartOptions,
  defaultSmallLineSeriesOptions,
} from '@/components/charts/CBLineChart'
import VCBBarChart, {
  defaultBarSeriesOptions,
  defaultChartOptions,

} from '@/components/charts/CBBarChart'

export default {
  name: 'Meter',

  components: { VCBLineChart, VCBBarChart },

  props: {
    meter: {
      type: Object,
      default: () => {}
    },

    lineChartOptions: {
      type: Object,
      default: () => {
        const o = { ...defaultSmallChartOptions }
        o.scales.yAxes[0].ticks.callback = label => `${label} W`
        return o
      }
    },

    barChartOptions: {
      type: Object,
      default: () => {
        const o = { ...defaultChartOptions }
        o.scales.yAxes[0].ticks.callback = label => `${label} kWh`
        return o
      }
    }
  },

  data: () => ({
    tab: null,
  }),

  computed: {
    energy () {
      return {
        day: this.meter.data?.energy.day / this.meter.ratio,
        week: this.meter.data?.energy.week / this.meter.ratio,
        month: this.meter.data?.energy.month / this.meter.ratio,
        year: this.meter.data?.energy.year / this.meter.ratio,
        total: this.meter.data?.energy.total / this.meter.ratio,
      }
    },

    series () {
      return {
        day: this.prepareChartData(this.$vuetify.lang.t('$vuetify.tenant.meters.day_series_title'), this.meter.data.series.day, 'HH:mm', true),
        week: this.prepareChartData(this.$vuetify.lang.t('$vuetify.tenant.meters.week_series_title'), this.meter.data.series.week, 'D.M.', false),
        month: this.prepareChartData(this.$vuetify.lang.t('$vuetify.tenant.meters.month_series_title'), this.meter.data.series.month, 'D.M.', false),
        year: this.prepareChartData(this.$vuetify.lang.t('$vuetify.tenant.meters.year_series_title'), this.meter.data.series.year, 'D.M.', false),
      }
    }
  },

  methods: {
    prepareLabels (series, format) {
      return series.time.map(t => moment.unix(t).format(format))
    },

    prepareValues (series, is_rate) {
      return series.value.map(v => is_rate
         ? getWattsPerSecond(v, this.meter.ratio, series.aggregation || 1)
         : v / this.meter.ratio
      )
    },

    prepareChartData (label, series, format, is_rate, is_bar) {
      return {
        labels: this.prepareLabels(series, format),
            datasets: [
          {
            label: `${label} ${this.meter.meter_id}`,
            data: this.prepareValues(series, is_rate),
            spanGaps: false,
            ...is_bar ? defaultBarSeriesOptions : defaultSmallLineSeriesOptions
          }
        ]
      }
    },

    chartClick (dataset, index) {
      console.log(dataset, index)
    },
  }
}
</script>

<style scoped>

</style>
