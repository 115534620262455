<template>
  <div>
    <resource-list
        :title="$route.meta.title"
        :icon="$route.meta.icon"
        resource="schedule"
        :fields="[
          { name: 'schedule_id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.id') },
          { name: 'title', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.title') },
          { name: 'cron', label: $vuetify.lang.t('$vuetify.views.routes.schedules.fields.cron_recipe') },
          { name: 'active', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.active') },
        ]"
        @field:changed="fieldChanged"
    >
      <template v-slot:buttons>
        <v-btn
            color="warning"
            class="mb-2 mr-2"
            to="/jobs"
            dark
        >
          {{ $vuetify.lang.t('$vuetify.views.routes.schedules.buttons.jobs') }}
        </v-btn>
      </template>
    </resource-list>
  </div>
</template>

<script>
import cronstrue from 'cronstrue'

import ResourceList from '@/components/ResourceList'

export default {
  name: 'VSchedules',
  components: { ResourceList },
  methods: {
    fieldChanged (field, item, value) {
      if (field.name === 'cron') {
        item.title = cronstrue.toString(value)
      }
    }
  }
}
</script>

<style scoped>

</style>
