<template>
  <resource-list
      :title="$route.meta.title"
      :icon="$route.meta.icon"
      resource="output"
      :fields="[
          { name: 'output_id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.id') },
          { name: 'name', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.name') },
          { name: 'title', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.title') },
          { name: 'params', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.params'), visible: { list: false, edit: true }  },
          { name: 'classname', label: $vuetify.lang.t('$vuetify.views.routes.outputs.fields.output_type'), type: 'list', list: outputTypes }
        ]"
      @field:changed="fieldChanged"
  />
</template>

<script>
import ResourceList from '@/components/ResourceList'
import output_types from '@/repositories/cb-server/api/output_types'

export default {
  name: 'VOutputs',
  components: { ResourceList },
  data: () => ({
    types: []
  }),

  async mounted () {
    this.types = await output_types.list()
  },

  computed: {
    outputTypes () {
      return this.types.map(
          item => ({
            name: item.name,
            value: item.name,
            item
          })
      )
    }
  },

  methods: {
    fieldChanged (field, item, value) {
      if (field.name === 'classname') {
        const type = field.list.find(item => item.name === value)

        if (type && type.item) {
          item.params = type.item.defaults
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
