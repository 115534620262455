import api from './index'

export default {
    generate (username, password) {
        return api.post('/auth/generate', { username, password })
    },

    login (username, password) {
        return api.post('/auth/login', { username, password })
    },

    logout (token) {
        return api.post('/auth/logout', token)
    },

    renew (token) {
        return api.post('/auth/renew', { token })
    },

    me () {
        return api.get('/auth/me')
    }
}
