<template>
  <div>
    <v-messages/>
    <v-login v-if="!status.loggedIn" @logged="logged = true"/>
    <v-view v-else></v-view>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import VMessages from '@/components/Messages'
import VView from './View'
import VLogin from './Login'

export default {
  name: 'AdminView',
  components: { VMessages, VView, VLogin },
  computed: {
    ...mapState('account', ['status']),
  }
}
</script>

<style scoped>

</style>
