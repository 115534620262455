import api from './index'

export default {
    summary (filter) {
        return api.get(`/serie/summary`, { params: filter })
    },

    data (filter) {
        return api.get(`/serie/data`, { params: filter })
    },

    deleteByMeterId (id) {
        return api.delete(`/serie/meter/${id}`)
    },

    deleteInfluxSeries (location, name, output) {
        return api.delete(`/serie/influx/${location}/${name}/${output}`)
    },

    getSensorDataCount (location, name, output) {
        return api.get(`/serie/influx/${location}/${name}/${output}`)
    },
}
