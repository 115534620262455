<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="rows"
        :item-key="idFieldName"
        :items-per-page="-1"
        :show-select="isSelect"
        :group-by="groupBy"
        :item-class="itemClass"
        @click:row="clickItem"
        class="elevation-1"
        single-select
    >
      <template v-slot:group.header="{items, isOpen, toggle}">
        <th :colspan="headers.length">
          <v-icon class="mb-1 mr-2" @click="toggle">
            {{ isOpen ? 'far fa-minus-square' : 'far fa-plus-square' }}
          </v-icon>
          {{ getGroupTitle(items) }}
        </th>
      </template>

      <template
          v-for="field in booleanFields"
          v-slot:[`item.${field.name}`]="{ item }"
      >
        <v-icon :key="field.name">
          <template v-if="item[field.name]">far fa-check-square</template>
          <template v-else>far fa-square</template>
        </v-icon>
      </template>

      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-icon v-if="icon" class="mb-1 mr-2">{{ icon }}</v-icon>
          <v-toolbar-title>{{ title.toUpperCase() }}</v-toolbar-title>
          <template v-if="filter && !$vuetify.breakpoint.mobile">
            <v-divider
                class="mx-4"
                inset
                vertical
            ></v-divider>
            <div class="red--text font-weight-thin">
              {{ $vuetify.lang.t('$vuetify.components.resource_list.using_filter') }}: {{ JSON.stringify(filter) }}
            </div>
          </template>

          <v-spacer></v-spacer>

          <v-btn
              v-if="canCreate && actionsEnabled"
              color="primary"
              class="mb-2"
              dark
              @click="dialog = true"
          >
            {{ $vuetify.lang.t('$vuetify.common.new') }} {{ title.toUpperCase() }}
          </v-btn>

          <slot name="buttons"/>

          <v-dialog
              v-if="dialog"
              v-model="dialog"
              max-width="800px"
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ editItemFormTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-resource-edit
                  :fields="editFields"
                  :relations="relatedResources"
                  :item="editedItem"
                  :filter="filter"
                  :read-only="dialogReadonly"
                  :defaults="defaults"
                  @field:changed="fieldChanged"
                />
              </v-card-text>

              <v-alert
                  v-if="editError"
                  class="ma-2"
                  type="error"
                  icon="mdi-alert"
                  prominent
                  dense
                  text
              >
                <pre v-html="editError"></pre>
              </v-alert>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                  {{ $vuetify.lang.t('$vuetify.common.cancel') }}
                </v-btn>
                <v-btn
                    v-if="!dialogReadonly"
                    color="blue darken-1"
                    text
                    @click="save"
                >
                  {{ $vuetify.lang.t('$vuetify.common.save') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h6">{{ $vuetify.lang.t('$vuetify.components.resource_list.delete_row_title') }}</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">{{ $vuetify.lang.t('$vuetify.common.cancel') }}</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{ $vuetify.lang.t('$vuetify.common.ok') }}</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            v-if="canRead && !canUpdate"
            small
            class="mr-2"
            @click="viewItem(item)"
        >
          mdi-eye-outline
        </v-icon>
        <v-icon
            v-if="canUpdate"
            small
            class="mr-2"
            @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            v-if="canDelete"
            small
            @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-alert
            v-if="resourceError"
            type="error"
            dense
            prominent
            outlined
        >{{ resourceError }}</v-alert>
        <v-btn
            icon
            color="primary"
        >
          <v-icon>fa-sync-alt</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {capitalize, keyBy, merge, values} from 'lodash'

import IsResource from '../mixins/IsResource'
import VResourceEdit from './ResourceEdit'
import { getProperty } from '@/utils/common'

export default {
  name: 'ResourceList',

  mixins: [IsResource],

  components: { VResourceEdit },

  props: {
    title: {
      type: String,
    },
    icon: {
      type: String,
    },
    isSelect: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    relations: {
      type: Array,
      default: () => [],
    },
    groupBy: {
      type: Array || String,
      default: () => [],
    },
    groupTitle: {
      type: Array || String,
      default: () => [],
    },
    actionsEnabled: {
      type: Boolean,
      default: true
    },
    defaults: {
      type: Object,
      default: () => ({})
    },
    forbidEditFields: {
      type: Array,
      default: () => []
    },
  },

  data: () => ({
    dialog: false,
    dialogReadonly: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    editError: null
  }),

  created () {
    this.updateDefault()
  },

  computed: {
    listFields () {
      return this.allFields.filter(
          field => this.fields.find(
              staticField => (staticField.name === field.name)
          ) && (!field.visible || field.visible.list)
      )
    },

    editFields () {
      return this.allFields.filter(
          field => !field.visible || field.visible.edit
      )
    },

    booleanFields () {
      return this.listFields.filter(
          field => field.type === 'boolean'
      )
    },

    /**
     * Resource and static fields together.
     * @return {FieldDef[] & *[]}
     */
    allFields () {
      let fields = values(
        merge(
          keyBy(this.resourceFields, 'name'),
          keyBy(this.fields, 'name')
        )
      )

      const names = this.fields.map(field => field.name)

      fields.sort(
          (i1, i2) => names.indexOf(i1.name) - names.indexOf(i2.name)
      )

      return fields
    },

    relatedResources () {
      return this.relations.map(relation => {
        const resourceRelation = this.resourceRelations.find(item => item.name === relation.name)
        return {
          ...resourceRelation,
          ...relation,
        }
      })
    },

    headers () {
      let fields = [...this.listFields]

      fields = fields.map(field => ({
        text: field.label,
        align: 'start',
        sortable: true,
        value: field.name
      }))

      if (this.actionsEnabled) {
        fields.push({
          text: this.$vuetify.lang.t('$vuetify.views.routes.common.fields.actions'),
          value: 'actions',
          sortable: false,
          align: 'end'
        })
      }

      return fields
    },

    rows () {
      return this.resourceRows || []
    },

    editItemFormTitle () {
      return this.editedIndex === -1 ? `New ${this.resourceTitle}` : `Edit ${this.resourceTitle}`
    },

    resourceTitle () {
      return this.resource.toUpperCase()
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    filter () {
      this.updateDefault()
    }
  },

  methods: {
    reloadItems () {
      this.init()
    },

    updateDefault () {
      this.defaultItem = { ...this.filter }
      this.editedItem = this.defaultItem
    },

    async editItem (item, readonly = false) {
      this.editedIndex = this.rows.indexOf(item)
      this.editedItem = await this.api.read(
          this.getId(item)
      )

      for (const field of this.forbidEditFields) {
        delete(this.editedItem[field])
      }

      this.dialogReadonly = readonly
      this.dialog = true
    },

    editItemById (id) {
      const item = this.rows.find(i => this.getId(i) === id)
      this.editItem(item)
    },

    deleteItem (item) {
      this.editedIndex = this.rows.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async viewItem (item) {
      await this.editItem(item, true)
    },

    fieldChanged (field, item, value) {
      if (field.name === 'name') {
        item.title = capitalize(value)
      }

      this.$emit('field:changed', field, item, value)
    },

    async deleteItemConfirm () {
      this.rows.splice(this.editedIndex, 1)
      await this.api.delete(
          this.getId(this.editedItem)
      )
      this.closeDelete()
    },

    close () {
      this.updateDefault()
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save () {
      try {
        if (this.editedIndex > -1) {
          const id = this.getId(this.editedItem)
          await this.api.update(id, this.editedItem)
        } else {
          await this.api.create(this.editedItem)
        }

        await this.init()

        this.close()
      } catch (e) {
        this.editError = e.data || e.message || e.toString()
      }
    },

    getGroupTitle (items) {
      return `${this.groupTitle[0]}: ${getProperty(items[0], this.groupBy[0])}`
    },

    clickItem (item) {
      this.$emit('click:item', item)
    },

    getSlotName (field) {
      return field.name
    },

    itemClass (item) {
      return item.blinking ? 'blinking-row' : ''
    }
  }
}
</script>

<style>
.blinking-row {
  animation: bounce-in 10.0s;
}
@keyframes bounce-in {
  0% {
    color: orangered;
  }
  100% {
    color: black;
  }
}
</style>
