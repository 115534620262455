<template>
  <div>
  <v-tabs
      v-model="tab"
      background-color="primary"
      dark
  >

    <v-tab>
      {{ $vuetify.lang.t('$vuetify.views.routes.meters.tabs.meters_title') }}
    </v-tab>

    <v-tab>
      {{ $vuetify.lang.t('$vuetify.views.routes.meters.tabs.dashboard') }}
    </v-tab>

    <v-tab>
      {{ $vuetify.lang.t('$vuetify.views.routes.meters.tabs.live_view') }}
    </v-tab>

    <v-tab :disabled="!meter">
      {{ $vuetify.lang.t('$vuetify.views.routes.meters.tabs.month_chart_title') }}
    </v-tab>

    <v-tab :disabled="!meter || !date">
      {{ $vuetify.lang.t('$vuetify.views.routes.meters.tabs.day_chart_title') }}
    </v-tab>
  </v-tabs>

  <v-tabs-items v-model="tab">

    <v-tab-item>
      <v-resource-list
        resource="meter"
        :title="$route.meta.title"
        :icon="$route.meta.icon"
        :filter="{ customer_id: customer }"
        :fields="[
          { name: 'meter_id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.id') },
          { name: 'customer_id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.customer_id'), visible: { list: false, edit: true } },
          { name: 'sensor_id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.sensor_id'), visible: { list: false, edit: true } },
          { name: 'tenant_id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.tenant_id'), visible: { list: false, edit: true } },
          { name: 'identifier', label: $vuetify.lang.t('$vuetify.views.routes.meters.fields.identifier') },
          { name: 'locality', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.locality') },
          { name: 'name', label: $vuetify.lang.t('$vuetify.views.routes.meters.fields.name'), visible: { list: false, edit: true } },
          { name: 'title', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.title') },
          { name: 'ratio', label: $vuetify.lang.t('$vuetify.views.routes.meters.fields.ratio'), visible: { list: false, edit: true } },
          { name: 'total', label: $vuetify.lang.t('$vuetify.views.routes.meters.fields.total'), visible: { list: false, edit: true } },
          { name: 'installation_date', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.installation_date') },
          { name: 'description', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.description') },
        ]"
        :group-by="[
            'sensor.title'
        ]"
        :group-title="[
            $vuetify.lang.t('$vuetify.views.routes.common.fields.sensor_id')
        ]"
        :forbid-edit-fields="[
          'series'
        ]"
        ref="list"
      />
    </v-tab-item>

    <v-tab-item v-if="canReadSeries">
      <v-meters-dashboard :meter="meter" @meter:edit="meterEdit" @meter:chart="meterChart" @meter:total="meterTotal"/>
    </v-tab-item>

    <v-tab-item v-if="canReadSeries">
      <v-meters-live-view :meter="meter"/>
    </v-tab-item>

    <v-tab-item v-if="canReadSeries">
      <v-card flat>
        <v-meter-month-chart :meter="meter" :date="date" @day:click="monthDayClick"></v-meter-month-chart>
      </v-card>
    </v-tab-item>

    <v-tab-item v-if="canReadSeries">
      <v-card flat>
        <v-meter-day-chart :meter="meter" :date="date"></v-meter-day-chart>
      </v-card>
    </v-tab-item>
  </v-tabs-items>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import VResourceList from '@/components/ResourceList'
import VMeterMonthChart from '@/views/AdminView/routes/Meters/MeterMonthChart'
import VMeterDayChart from '@/views/AdminView/routes/Meters/MeterDayChart'
import VMetersDashboard from '@/views/AdminView/routes/Meters/MetersDashboard'
import VMetersLiveView from '@/views/AdminView/routes/Meters/MetersLiveView'
import HasRBAC from '@/mixins/HasRBAC'

export default {
  name: 'VMeters',

  components: {
    VMetersDashboard,
    VResourceList,
    VMeterMonthChart,
    VMeterDayChart,
    VMetersLiveView,
  },

  mixins: [HasRBAC],

  data: () => ({
    tab: null,
    meter: null,
    date: null
  }),

  computed: {
    ...mapState('customer', ['customer']),

    canReadSeries () {
      return this.canReadResource('series')
    },
  },

  methods: {
    meterClicked (meter) {
      this.meter = meter
      this.date = null
      this.tab = 3
    },

    monthDayClick (day) {
      this.date = new Date(day)
      this.tab = 4
    },

    meterEdit (meter) {
      this.tab = 0
      this.$refs.list.editItemById(meter.meter_id)
    },

    meterChart (meter) {
      this.meterClicked(meter)
    },

    meterTotal (meter) {
      console.log(`meter total: ${meter.meter_id}`)
    },
  }
}
</script>

<style scoped>

</style>
