import { Line, mixins } from 'vue-chartjs'

export const defaultLineSeriesOptions = {
    backgroundColor: 'rgba(17,90,185,0.2)',
    borderColor: 'rgb(17,90,185)',
    borderJoinStyle: 'round',
    borderWidth: 1,
    pointRadius: 1,
    tension: 0.3,
}

export const defaultSmallLineSeriesOptions = {
    backgroundColor: 'rgba(17,90,185,0.2)',
    borderColor: 'rgb(17,90,185)',
    borderJoinStyle: 'round',
    borderWidth: 1,
    pointRadius: 0.5,
    tension: 0.3,
}

export const defaultChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
        yAxes: [{
            ticks: {
                suggestedMin: 0,
            }
        }],
    }
}

export const defaultSmallChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
        xAxes: [{
            display: true,
            grid: true,
            ticks: {
                autoSkip: true,
                autoSkipPadding: 5,
            }
        }],
        yAxes: [{
            display: true,
            grid: true,
            ticks: {
                suggestedMin: 0,
                autoSkipPadding: 5,
            }
        }],
    },
}


export default {
    name: 'VCBLineChart',
    extends: Line,
    mixins: [mixins.reactiveProp],
    props: {
        options: {
            type: Object,
            default: null
        }
    },
    mounted () {
        this.renderChart(this.chartData, this.options)
    }
}
