<template>
  <div>
    <v-container v-if="firstLoading">
      <v-skeleton-loader
          class="mt-16"
          type="table-heading, list-item-two-line, image, table-tfoot"
      ></v-skeleton-loader>
    </v-container>
    <v-container v-else>
      <h1 class="ml-1 white--text">{{ title }} </h1>

      <h2 class="mb-3 white--text">
          <v-chip color="blue lighten-3"><v-icon small>fa-chart-line</v-icon>&nbsp;{{ dailyTotal | toKWh }} ({{$vuetify.lang.t('$vuetify.tenant.meters.day_label')}})</v-chip>&nbsp;
          <v-chip color="green lighten-3"><v-icon small>fa-chart-column</v-icon>&nbsp;{{ weeklyTotal | toKWh }} ({{$vuetify.lang.t('$vuetify.tenant.meters.week_label')}})</v-chip>&nbsp;
          <v-chip color="yellow lighten-3"><v-icon small>fa-chart-column</v-icon>&nbsp;{{ monthlyTotal | toKWh}} ({{$vuetify.lang.t('$vuetify.tenant.meters.month_label')}})</v-chip>&nbsp;
          <v-chip color="orange lighten-3"><v-icon small>fa-chart-column</v-icon>&nbsp;{{ yearlyTotal | toKWh }} ({{$vuetify.lang.t('$vuetify.tenant.meters.year_label')}})</v-chip>
        <small class="mx-3 mt-1 font-weight-thin text-no-wrap">
          {{$vuetify.lang.t('$vuetify.tenant.meters.updated_at')}} {{ updatedAtStr }}
          <v-btn
              icon
              @click="refresh"
          >
            <v-progress-circular
                v-show="loading"
                :size="20"
                color="white"
                indeterminate
            />
            <v-icon
                v-show="!loading"
                small
                color="white"
            >fa-refresh</v-icon>
          </v-btn>
        </small>
      </h2>

      <v-card class="rounded-xl" elevation="20">
        <v-tabs
            v-model="tab"
            v-show="meters.length > 1"
        >
          <v-tab
              v-for="meter in meters"
              :key="'tab_' + meter.meter_id"
          >
            <v-icon small>fa-tachometer-alt</v-icon>&nbsp;{{ meter.identifier }}
          </v-tab>
        </v-tabs>

        <v-tabs-items
            v-model="tab"
        >
          <v-tab-item
              v-for="meter in meters"
              :key="'titem_' + meter.meter_id"
          >
            <v-meter :meter="meter"/>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import tenants from '@/repositories/cb-server/api/tenants'
import HasQueryParams from '@/mixins/HasQueryParams'
import VMeter from './Meter'

const metersSum = what => (accum, current) => accum + current.data?.energy[what] / current.ratio

export default {
  name: 'TenantView',

  components: { VMeter },

  mixins: [HasQueryParams],

  data: () => ({
    loaded: false,
    meters: [],
    tab: null,
    updatedAt: null,
    refresher: null,
    loading: true,
  }),

  created () {
    this.refresher = setInterval(() => this.refresh(), 60000)
  },

  destroyed() {
    clearInterval(this.refresher)
  },

  mounted () {
    this.refresh()
  },

  computed: {
    title () {
      return this.meters.length ? this.meters[0]?.tenant?.title : ''
    },

    dailyTotal () {
      return this.meters.reduce(metersSum('day'), 0)
    },

    weeklyTotal () {
      return this.meters.reduce(metersSum('week'), 0)
    },

    monthlyTotal () {
      return this.meters.reduce(metersSum('month'), 0)
    },

    yearlyTotal () {
      return this.meters.reduce(metersSum('year'), 0)
    },

    updatedAtStr () {
      return this.updatedAt ? this.updatedAt.toLocaleString() : '---'
    },

    firstLoading () {
      return !this.meters.length && this.loading
    }
  },

  filters: {
    toKWh (value) {
      return `${value.toFixed(1)} kWh`
    },
  },

  methods: {
    async refresh() {
      try {
        this.loading = true
        try {
          this.updatedAt = new Date()

          const meters = await tenants.meters(
              this.queryParams.get('token')
          )

          const values = await tenants.live(
              this.queryParams.get('token')
          )

          meters.forEach(meter => {
            const series = values.find(
                item => item.meter_id === meter.meter_id
            )

            const updated = ( meter.total_pulses !== series?.total )

            if (updated) {
              const date = new Date()
              meter.updated_at = date.toLocaleTimeString()
              meter.blinking = true
              setTimeout(() => {
                meter.total = meter.total + ' '
                delete ( meter.blinking )
              }, 2000)
            }

            meter.total_pulses = series?.total
            meter.total = ( series?.total / meter.ratio ).toFixed(2) + ' kWh'
            meter.data = series
          })

          this.meters = meters
        } finally {
          this.loading = false
        }
      } catch (e) {
        this.meters = []
      }
    }
  }
}
</script>

<style scoped>
#transparent-tabs-items {
  background-color: transparent !important;
}
</style>
