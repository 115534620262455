export function generateHash(size) {
    return Math.random().toString(36).substr(2, size)
}

export function getProperty(project, path){
    return path
        .split('.')
        .reduce(
            (res, prop) => res[prop],
            project
        )
}

export function getWattsPerSecond(pulses, ratio, aggregation) {
    return pulses ? (pulses * 60) / aggregation / (ratio / 1000) : null
}

export const humanReadableValueMilestones = {
    0.000000000001: 'p',
    0.000000001: 'n',
    0.000001: 'µ',
    0.001: 'm',
    1: '',
    1000: 'k',
    1000000: 'M',
    1000000000: 'G',
    1000000000000: 'T',
}

export function getHumanReadableValue(value, suffix, anchor) {
    const milestones = Object.entries(humanReadableValueMilestones)

    const milestone = anchor
        ? milestones.find(([, v]) => v === anchor)
        : milestones.find(([k]) => value < k * 1000)

    if (!milestone) {
        return value + suffix
    }

    const rounded = Math.round(value / milestone[0] * 100) / 100

    return `${rounded} ${milestone[1]}${suffix}`
}
