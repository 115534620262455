<template>
  <resource-list
      :title="$route.meta.title"
      :icon="$route.meta.icon"
      resource="sensor"
      :filter="{ customer_id: customer }"
      :fields="[
          { name: 'sensor_id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.id') },
          { name: 'customer_id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.customer_id'), visible: { list: false, edit: true } },
          { name: 'building_id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.building_id'), visible: { list: false, edit: true } },
          { name: 'sensor_type_id', label: $vuetify.lang.t('$vuetify.views.routes.sensors.fields.sensor_type'), visible: { list: false, edit: true } },
          { name: 'name', label: $vuetify.lang.t('$vuetify.views.routes.sensors.fields.name') },
          { name: 'title', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.title') },
          { name: 'location', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.locality') },
          { name: 'description', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.description'), visible: { list: false, edit: true } },
          { name: 'installation_date', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.installation_date') },
          { name: 'mac', label: $vuetify.lang.t('$vuetify.views.routes.sensors.fields.mac_address') },
          { name: 'ip', label: $vuetify.lang.t('$vuetify.views.routes.sensors.fields.ip_address') },
          { name: 'metadata', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.meta_data'), visible: { list: false, edit: true } },
          { name: 'coords', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.coords'), visible: { list: false, edit: true } },
      ]"
      :group-by="[
            'building.title'
        ]"
      :group-title="[
            $vuetify.lang.t('$vuetify.views.routes.common.fields.building_id')
        ]"
  />
</template>

<script>
import ResourceList from '@/components/ResourceList'
import {mapState} from 'vuex'

export default {
  name: 'VSensors',
  components: { ResourceList },
  computed: {
    ...mapState('customer', ['customer']),
  },
}
</script>

<style scoped>

</style>
