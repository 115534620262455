<template>
  <resource-list
      :title="$route.meta.title"
      :icon="$route.meta.icon"
      resource="report"
      :filter="{ customer_id: customer }"
      :fields="[
          { name: 'report_id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.id') },
          { name: 'customer_id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.customer_id'), visible: { list: false, edit: true } },
          { name: 'generator_id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.generator_id'), visible: { list: false, edit: true }  },
          { name: 'params', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.params'), visible: { list: false, edit: true }  },
          { name: 'title', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.title') },
          { name: 'generator.title', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.generator_id'), visible: { list: true, edit: false } },
        ]"
  />
</template>

<script>
import ResourceList from '@/components/ResourceList'
import {mapState} from 'vuex'

export default {
  name: 'VReports',
  components: { ResourceList },
  computed: {
    ...mapState('customer', ['customer']),
  },
}
</script>

<style scoped>

</style>
