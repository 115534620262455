<template>
  <div>
    <v-navigation-drawer
        v-model="drawer"
        :color="color"
        mini-variant.sync
        app
        dark
    >
      <v-list>
        <v-list-item link @click="profileMenu = true">
          <v-list-item-avatar>
            <v-gravatar :email="username"></v-gravatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ username }}
            </v-list-item-title>
            <v-list-item-subtitle>(user_id:{{ userId }})</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-menu
          v-model="profileMenu"
          min-width="250"
      >
        <v-list nav>
          <v-list-item @click="drawer=false">
            <v-list-item-icon>
              <v-icon>fa-arrow-alt-circle-left</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $vuetify.lang.t('$vuetify.menu.profile.navigation') }}</v-list-item-title>
          </v-list-item>
          <v-divider/>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>fa-sign-out-alt</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $vuetify.lang.t('$vuetify.menu.profile.logout') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-divider></v-divider>

      <v-list
          nav
          dense
      >
        <div v-for="(items, group) in groups" :key="group">
          <v-subheader>{{ group.toUpperCase() }}</v-subheader>
          <v-list-item v-for="route in items" :key="route.route" :to="route.path">
            <v-list-item-icon>
              <v-icon>{{ route.meta.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ route.meta.title }}</v-list-item-title>
          </v-list-item>
        </div>
      </v-list>

      <v-footer absolute>
        <v-icon>fab fa-git-alt</v-icon>&nbsp;
        <small>git commit: <strong>{{ version }}</strong></small>
      </v-footer>
    </v-navigation-drawer>

    <v-app-bar
        elevation="5"
        :color="color"
        dark
        app
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-icon class="ml-4 mr-4">fa-user-tie</v-icon>

      <v-select
          :items="customerList"
          :value="customer"
          @change="setCustomer($event)"
          item-value="id"
          item-text="title"
          style="margin-bottom: -30px"
          :label="$vuetify.lang.t('$vuetify.views.view.customer_select_title')"
          color="red--text"
      ></v-select>
    </v-app-bar>

    <v-main>
      <v-container
        :class="{
          'pa-3': !$vuetify.breakpoint.mobile,
          'pa-0': $vuetify.breakpoint.mobile
        }" fluid
      >
        <router-view></router-view>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { routes } from '@/router'
import { mapState, mapActions, mapMutations } from 'vuex'
import {groupBy} from 'lodash/collection'
import theme from '@/config/theme'
import rbac from '@/services/rbac'

export default {
  name: 'VView',
  computed: {
    ...mapState('account', ['user', 'grants']),
    ...mapState('customer', ['customerList', 'customer']),

    color() {
      return theme.color
    },

    username() {
      return this.user?.username
    },

    userId() {
      return this.user?.user_id
    },

    routes() {
      return routes.filter(
        route => this.grants && rbac.check(
          ['readOwn', 'readAny'],
          route.meta.rbac.resource
        )
      )
    },

    groups() {
      return groupBy(this.routes, (item) => item.meta.group)
    },

    version() {
      return process.env.VUE_APP_GIT_HASH
    },
  },

  watch: {
    grants (value) {
      if (value) this.refresh()
    },
  },

  data: () => ({
    drawer: null,
    profileMenu: false
  }),

  created () {
    if (this.user) this.refresh()
  },

  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('customer', ['refresh']),
    ...mapMutations('customer', ['setCustomer']),
  }
}
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(127, 127, 127, 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(127, 127, 127, 0.5);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(127, 127, 127, 0.1);
}
</style>
