import resource from '@/repositories/cb-server/api/resource'
import {mapState} from 'vuex'
import HasRBAC from '@/mixins/HasRBAC'

/**
 * @typedef {{
 *   name: String,
 *   type: 'string'|'number'|'boolean'|'memo',
 *   limit: Number,
 *   limit: Number,
 *   label: String,
 *   primary: Boolean,
 *   required: Boolean,
 *   readonly: Boolean,
 *   reference: {
 *       model: String,
 *       key: String,
 *   },
 *   disabled: Boolean,
 *   visible?: {
 *       list?: boolean,
 *       edit?: boolean
 *   }
 * }} FieldDef
 */

export default {
    props: {
        id: {
          type: Number,
          default: null
        },
        resource: {
            type: String,
        },
        filter: {
            type: Object,
        },
    },

    mixins: [HasRBAC],

    data: () => ({
        resourceDefs: { fields: [], relations: [] },
        resourceRows: null,
        resourceError: null,
    }),

    async created () {
        await this.init()
    },

    computed: {
        ...mapState('customer', ['customer']),
        api () {
            return resource(this.resource)
        },

        /**
         * Returns array of resource field defs.
         * @return {FieldDef[]}
         */
        resourceFields () {
            return Object.entries(this.resourceDefs.fields)
                .map(
                    ([name, value]) => ({
                        name,
                        ...value,
                        label: value.label || value.name || value.reference,
                        readonly: value.primary,
                        disabled: value.primary,
                        type: value.type,
                        limit: value.limit
                    })
            )
        },

        resourceRelations () {
            return this.resourceDefs.relations
        },

        resourceData () {
            return this.resourceRows
        },

        idField () {
            return this.resourceFields.find(field => field.primary)
        },

        idFieldName () {
            return this.idField?.name
        },

        canRead () {
            return this.canReadResource(this.resource)
        },

        canUpdate () {
            return this.canUpdateResource(this.resource)
        },

        canCreate () {
            return this.canCreateResource(this.resource)
        },

        canDelete () {
            return this.canDeleteResource(this.resource)
        },

        canReadAny () {
            return this.canReadAnyResource(this.resource)
        },

        canUpdateAny () {
            return this.canUpdateAnyResource(this.resource)
        },

        canCreateAny () {
            return this.canCreateAnyResource(this.resource)
        },

        canDeleteAny () {
            return this.canDeleteAnyResource(this.resource)
        },

        canReadOwn () {
            return this.canReadOwnResource(this.resource)
        },

        canUpdateOwn () {
            return this.canUpdateOwnResource(this.resource)
        },

        canCreateOwn () {
            return this.canCreateOwnResource(this.resource)
        },

        canDeleteOwn () {
            return this.canDeleteOwnResource(this.resource)
        }
    },

    watch: {
        customer() {
            this.init()
        },
    },

    methods: {
        async init () {
            this.resourceError = null
            try {
                this.resourceDefs = await this.api.fields()
                this.resourceRows = await this.api.list(this.filter)
                this.$emit('items', this.resourceRows)
            } catch (e) {
                this.resourceError = e.message
            }
        },

        analyzeField (field) {
            if (field.reference) {
                return {
                    type: 'reference',
                    reference: field.reference
                }
            }

            const parsed = field.type.split(/[()]+/)

            let type = 'string'
            let limit = null

            switch (parsed[0]) {
                case 'VARCHAR': {
                    type = 'string'
                    limit = +parsed[1]
                    break
                }

                case 'MEDIUMTEXT':
                case 'LONGTEXT':
                case 'TEXT': {
                    type = 'memo'
                    break
                }

                case 'SMALLINT':
                case 'MEDIUMINT':
                case 'BIGINT':
                case 'INT': {
                    type = 'number'
                    break
                }

                case 'TINYINT': {
                    type = 'boolean'
                    break
                }

                case 'DOUBLE': {
                    type = 'float'
                    break
                }

                case 'DATETIME': {
                    type = 'datetime'
                    break
                }

                case 'DATE': {
                    type = 'date'
                    break
                }

                case 'JSON': {
                    type = 'json'
                    break
                }

                case 'POINT': {
                    type = 'point'
                    break
                }
            }

            return {
                type, limit
            }
        },

        getId (item) {
            return this.idFieldName ? item[this.idFieldName] : null
        }
    }
}
