<template>
  <resource-list
      :title="$route.meta.title"
      :icon="$route.meta.icon"
      resource="generator"
      :fields="[
          { name: 'id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.id') },
          { name: 'title', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.title') },
          { name: 'name', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.name'), visible: { list: false, edit: true } },
          { name: 'classname', label: $vuetify.lang.t('$vuetify.views.routes.generators.fields.generator_type'), type: 'list', list: generatorTypes },
          { name: 'params', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.params'), visible: { list: false, edit: true }  },
        ]"
      @field:changed="fieldChanged"
  />
</template>

<script>
import ResourceList from '@/components/ResourceList'
import generator_types from '@/repositories/cb-server/api/generator_types'

export default {
  name: 'VGenerators',
  components: { ResourceList },

  data: () => ({
    types: []
  }),

  async mounted () {
    this.types = await generator_types.list()
  },

  computed: {
    generatorTypes () {
      return this.types.map(
          item => ({
            name: item.name,
            value: item.name,
            item
          })
      )
    }
  },

  methods: {
    fieldChanged (field, item, value) {
      if (field.name === 'classname') {
        const type = field.list.find(item => item.name === value)

        if (type && type.item) {
          item.params = type.item.defaults
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
