<template>
  <div>
    <v-resource-list
      resource="meter"
      :title="$vuetify.lang.t('$vuetify.views.routes.meters.live_view.title')"
      icon="fa-heart-pulse"
      :filter="{ customer_id: customer }"
      :fields="[
        { name: 'meter_id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.id') },
        { name: 'identifier', label: $vuetify.lang.t('$vuetify.views.routes.meters.fields.identifier') },
        { name: 'locality', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.locality') },
        { name: 'name', label: $vuetify.lang.t('$vuetify.views.routes.meters.fields.name'), visible: { list: false, edit: true } },
        { name: 'title', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.title') },
        { name: 'description', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.description') },
        { name: 'total', label: $vuetify.lang.t('$vuetify.views.routes.meters.live_view.total') },
        { name: 'updated_at', label: $vuetify.lang.t('$vuetify.views.routes.meters.live_view.updated_at') },
      ]"
      :group-by="[
          'sensor.title'
      ]"
      :group-title="[
          $vuetify.lang.t('$vuetify.views.routes.common.fields.sensor_id')
      ]"
      :actions-enabled="false"
      ref="list"
      @items="itemsLoaded"
    >
      <template v-slot:buttons>
        <v-btn
            color="warning"
            class="mb-2 mr-2"
            @click="refreshValues()"
            dark
        >
          {{ $vuetify.lang.t('$vuetify.common.refresh') }}
        </v-btn>
      </template>
    </v-resource-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import HasRBAC from '@/mixins/HasRBAC'
import VResourceList from '@/components/ResourceList'
import live from '@/repositories/cb-server/api/live'
import { getHumanReadableValue } from '@/utils/common'

export default {
  name: 'MetersLiveView',
  components: { VResourceList },
  mixins: [HasRBAC],

  data: () => ({
    meters: {},
    loaded: false,
    refresher: null,
  }),

  computed: {
    ...mapState('customer', ['customer']),

    canReadMeter () {
      return this.canReadResource('meter')
    },
  },

  created () {
    this.refesher = setInterval(
        () => this.refreshValues(),
        10000
    )
  },

  destroyed () {
    clearInterval(this.refesher)
  },

  methods: {
    itemsLoaded(items) {
      this.meters = items
      this.loaded = true
      this.refreshValues()
    },

    async refreshValues () {
      if (!this.loaded) return

      try {
        const values = await live.totals()

        this.meters.forEach(meter => {
          const series = values.find(
              item => item.meter_id === meter.meter_id
          )

          const updated = (meter.total_pulses !== series?.total)

          if (updated) {
            const date = new Date()
            meter.updated_at = date.toLocaleTimeString()
            meter.blinking = true
            setTimeout(() => {
              meter.total = meter.total + ' '
              delete(meter.blinking)
            }, 2000)
          }

          meter.total_pulses = series?.total
          meter.total = getHumanReadableValue(series?.total / meter.ratio * 1000, 'Wh', 'k')
        })
      } catch (e) {
        this.meters.forEach(meter => {
          meter.total = null
          meter.updated_at = null
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
