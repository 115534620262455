<template>
  <div>
    <v-resource-grid
        v-if="canReadMeter && canReadSeries"
        resource="meter"
        :filter="{ customer_id: customer }"
        cell-title-field="sensor.title"
        group-by="sensor.title"
        @items="itemsLoaded($event)"
    >
      <template v-slot:group="{ group }">
        <v-icon class="mr-3">fa-microchip</v-icon>{{ $vuetify.lang.t('$vuetify.views.routes.common.fields.sensor_id') }}: {{ group.name }}
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            class="mb-2 mr-2"
            dark
            @click="refreshChart(group.items)"
        >
          {{ $vuetify.lang.t('$vuetify.views.routes.meters.dashboard.refresh_meters_group') }}
        </v-btn>
      </template>

      <template v-slot:cell="{ item }">
        <v-card
          class="ma-0 pa-0"
          elevation="0"
          v-for="scope in [{
            meter: item,
            serie: getSerieData(item.meter_id)
          }]"
          :key="scope.meter.meter_id"
        >
          <v-card-title>
            <small><v-icon class="mr-2" small>fa-tachometer-alt</v-icon>{{ scope.meter.locality }}; {{ scope.meter.description }}; {{ scope.meter.identifier }}</small>
            <v-spacer></v-spacer>

            <v-tooltip v-if="canUpdateMeter" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="editTotal(scope.meter)" disabled>
                  <v-icon small>fa-tachometer-alt</v-icon>
                </v-btn>
              </template>
              <span>{{ $vuetify.lang.t('$vuetify.views.routes.meters.dashboard.total_set_button_tooltip') }}</span>
            </v-tooltip>
            <v-tooltip v-if="canUpdateMeter" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="editMeter(scope.meter)">
                  <v-icon small>fa-edit</v-icon>
                </v-btn>
              </template>
              <span>{{ $vuetify.lang.t('$vuetify.views.routes.meters.dashboard.edit_button_tooltip') }}</span>
            </v-tooltip>
            <v-tooltip v-if="canReadSeries" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="showChart(scope.meter)">
                  <v-icon small>fa-chart-bar</v-icon>
                </v-btn>
              </template>
              <span>{{ $vuetify.lang.t('$vuetify.views.routes.meters.dashboard.chart_button_tooltip') }}</span>
            </v-tooltip>
            <v-tooltip v-if="canReadSeries" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="refreshChart([scope.meter])" :loading="scope.serie.loading">
                  <v-icon small>fa-sync-alt</v-icon>
                </v-btn>
              </template>
              <span>{{ $vuetify.lang.t('$vuetify.views.routes.meters.dashboard.refresh_button_tooltip') }}</span>
            </v-tooltip>
          </v-card-title>
          <v-card-subtitle>{{ scope.meter.title }} ({{ scope.meter.name }})</v-card-subtitle>

          <v-card-text>
            <v-c-b-line-chart
                v-if="scope.serie && scope.serie.chart"
                style="height: 150px"
                :chart-data="scope.serie.chart"
                :options="defaultSmallChartOptions"
            />
            <div class="mt-4">
              <v-chip
                  class="ma-2"
                  color="red"
                  outlined
                  label
                  @click="changeSeriesDateDialog(scope.meter.meter_id, scope.serie.date)"
              >
                <v-icon left>mdi-calendar</v-icon>
                <span
                    :class="{ 'font-weight-black': !isToday(scope.serie.date) }"
                >{{ formatDate(scope.serie.date) }}</span>
              </v-chip>
              <v-chip
                  class="ma-2"
                  color="green"
                  outlined
                  label
              >
                <v-icon left>mdi-graph</v-icon>
                {{ scope.serie.sum / scope.meter.ratio * 1000 | humanReadableValue('Wh') }}
              </v-chip>
              <v-chip
                  class="ma-2"
                  color="blue"
                  outlined
                  label
              >
                <v-icon left>mdi-flash</v-icon>
                <strong>{{ scope.serie.total / scope.meter.ratio * 1000 | humanReadableValue('Wh', 'k') }}</strong>
              </v-chip>
            </div>
          </v-card-text>
        </v-card>
      </template>
    </v-resource-grid>
    <v-alert
        v-else
        border="bottom"
        colored-border
        type="warning"
        elevation="2"
    >
      {{ $vuetify.lang.t('$vuetify.views.routes.meters.dashboard.no_enough_rights') }}
    </v-alert>
    <v-dialog v-if="pickingSeriesDate" v-model="pickingSeriesDate" max-width="300" hide-overlay>
      <v-date-picker
          v-model="pickingSeriesDate.date"
          @click:date="changeSeriesDate(pickingSeriesDate)"
      />
    </v-dialog>
  </div>
</template>

<script>
import VResourceGrid from '@/components/ResourceGrid'
import {mapState} from 'vuex'
import serie from '@/repositories/cb-server/api/serie'
import moment from 'moment'
import {keyBy} from 'lodash/collection'
import {mapValues} from 'lodash/object'
import VCBLineChart, {defaultSmallChartOptions, defaultSmallLineSeriesOptions} from '@/components/charts/CBLineChart'
import {getWattsPerSecond} from '@/utils/common'
import HasRBAC from '@/mixins/HasRBAC'
import { humanReadableValue } from '@/filters/hrv'

export default {
  name: "VMetersDashboard",
  components: {VCBLineChart, VResourceGrid},

  props: {
    meter: {
      type: Object,
      default: () => null
    },
  },

  mixins: [HasRBAC],

  filters: {
    humanReadableValue,
  },

  data: () => ({
    series: [],
    meters: {},
    pickingSeriesDate: null,
  }),

  computed: {
    ...mapState('customer', ['customer']),

    defaultSmallChartOptions: () => {
      const o = { ...defaultSmallChartOptions }
      o.scales.yAxes[0].ticks.callback = label => `${humanReadableValue(label, 'W')}`
      return o
    },

    canReadMeter () {
      return this.canReadResource('meter')
    },

    canUpdateMeter () {
      return this.canUpdateResource('meter')
    },

    canReadSeries () {
      return this.canReadResource('series')
    },
  },

  methods: {
    async itemsLoaded (meters) {
      this.meters = meters
      this.series = await this.getData(
          meters.map(meter => meter.meter_id),
          moment()
      )
    },

    async getData(ids, date) {
      const data = await serie.summary({
        meter_id: ids,
        date: moment(date).format('YYYY-MM-DD')
      })

      data.forEach(item => item.loading = false)

      return mapValues(
          keyBy(data,'meter_id')
      )
    },

    getSerieData (id) {
      const data = this.series[id]

      if (!data) {
        return {}
      }

      return {
        ...data,
        chart: {
          labels: data.values.time.map(
              t => moment.unix(t).format('HH:mm')
          ),
          datasets: [
            {
              data: data.values.value.map(
                  v => getWattsPerSecond(v, data.values.ratio, data.values.aggregation)
              ),
              ...defaultSmallLineSeriesOptions,
            }
          ],
        }
      }
    },

    showChart (meter) {
      this.$emit('meter:chart', meter)
    },

    editMeter (meter) {
      this.$emit('meter:edit', meter)
    },

    editTotal (meter) {
      this.$emit('meter:total', meter)
    },

    nullData (id, date) {
      return {
        meter_id: id,
        date: date,
        values: {
          time: [],
          value: [],
        }
      }
    },

    async refreshChart(meters) {
      const ids = meters.map(meter => meter.meter_id)

      let data = null
      try {
        const series = ids.map(id => this.series[id])

        series.forEach(serie => serie.loading = true)

        data = await this.getData(ids, new Date())
      } catch (e) {
        data = ids.map(
            id => this.nullData(id, new Date())
        )
      } finally {
        this.series = {
          ...this.series,
          ...data
        }
      }
    },

    formatDate (date) {
      return moment(date).toDate().toLocaleDateString()
    },

    isToday(date) {
      return moment().isSame(moment(date), 'day')
    },

    changeSeriesDateDialog (id, date) {
      this.pickingSeriesDate = {
        id, date
      }
    },

    async changeSeriesDate (picked) {
      let data = null
      try {
        data = await this.getData(picked.id, picked.date)
      } catch (e) {
        data = this.nullData(picked.id, picked.date)
      } finally {
        this.series[picked.id] = data[picked.id]
        this.pickingSeriesDate = null
      }
    }
  }
}
</script>

<style scoped>

</style>
