import storage from '../utils/storage'
import resource from '@/repositories/cb-server/api/resource'
import rbac from '@/services/rbac'

const state = {
    customer: storage.get('customer'),
    customerList: [],
}

const actions = {
    async refresh({ commit, rootState }) {
        if (!rootState.account.user) return

        const api = resource('customer')

        const readAny = rbac.check(['readAny'], 'customer')

        const user = rootState.account.user

        const where = !readAny && user ? { customer_id: user?.customer_id } : null

        const customers = await api.list(where)

        commit('setCustomerList', customers.map(
            item => ({
                id: item.customer_id,
                title: item.title
            })
        ))

        let customer = null

        if (state.customer) {
            customer = customers.find(
                item => item.id === state.customer.id
            )
        } else if (customers.length) {
            customer = customers[0]
        }

        commit('setCustomer', customer?.customer_id)
    },
}

const mutations = {
    setCustomer(state, payload) {
        state.customer = payload
        storage.set('customer', payload)
    },
    setCustomerList(state, payload) {
        state.customerList = payload
    },
}

export const customer = {
    namespaced: true,
    state,
    actions,
    mutations
}
