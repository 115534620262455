<template>
  <resource-list
      :title="$route.meta.title"
      :icon="$route.meta.icon"
      resource="building"
      :filter="{ customer_id: customer }"
      :fields="[
          { name: 'building_id', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.id') },
          { name: 'name', label: $vuetify.lang.t('$vuetify.views.routes.buildings.fields.name'), visible: { list: false, edit: true } },
          { name: 'title', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.title') },
          { name: 'coords', label: $vuetify.lang.t('$vuetify.views.routes.common.fields.coords'), visible: { list: false, edit: true } },
        ]"
  />
</template>

<script>
import ResourceList from '@/components/ResourceList'
import { mapState } from 'vuex'

export default {
  name: 'VBuildings',
  components: { ResourceList },
  computed: {
    ...mapState('customer', ['customer']),
  },
}
</script>

<style scoped>

</style>
